// src/pages/Portal.jsx
import React, { useState, useEffect } from 'react';
import { useUser } from '../hooks/useUser';
import { getUrl } from 'aws-amplify/storage';
import { useNavigate } from 'react-router-dom';
import ButtonCard from '../components/ButtonCard';

import { listOrders } from '../graphql/queries';
import { client } from '../App';

// Joyride + relevant exports
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { updateUserAttributes } from '@aws-amplify/auth'; 

export default function Portal() {
  const { user, userAttrs, loading,
    orders, loadingOrders, ordersError,
    refreshOrders, // if you want to manually force an orders refresh 
    refreshUser  } = useUser();
  const navigate = useNavigate();
  

  // For the “Show Details” modal
  const [selectedOrder, setSelectedOrder] = useState(null); // store the order object

  // --------------------------------------
  // JOYRIDE STATES + STEPS
  // --------------------------------------
  const [tutorialRun, setTutorialRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  function MyCustomTooltip(props) {
    const {
      step,
      isLastStep,
      closeProps,
    } = props;

    return (
      <div style={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: 10,
        padding: '1rem',
        maxWidth: 300,
        position: 'relative'
      }}>
        {step.title && <h3 style={{ marginTop: 0, textAlign: 'center', fontWeight:'500', fontSize:'1.2rem', marginBottom: '0.3rem' }}>{step.title}</h3>}
        <p>{step.content}</p>
        <div style={{ marginTop: '0.5rem', textAlign: 'right' }}>
          {!isLastStep && (
            <button
              {...closeProps}
              style={{
                padding: '0.5rem 1rem',
                backgroundColor: '#ccc',
                color: '#333',
                borderRadius: '25px'
              }}
            >
              Skip
            </button>
          )}
        </div>
      </div>
    );
  }

  function handleTutorialNextStep(){
    if(tutorialRun) {
      setStepIndex(stepIndex + 1)
    }
  }

  function handleTutorialPrevStep(){
    setStepIndex(stepIndex - 1)
  }

  function handleTutorialSkip(){
    setStepIndex(0);
    setTutorialRun(false);
  }

  const tutorialSteps = [
    {
      target: '#tutorial-order-form-button',
      title: 'Place an Order',
      content: 'Click on "Go to Order Form" button to place a new order!',
      disableBeacon: true,
    },
  ];

  const HEADER_OFFSET = 60;

  // Decide whether to auto-run tutorial
  useEffect(() => {
    if (userAttrs && userAttrs['custom:hasOnboarded']) {
      const tutorialCompleted = userAttrs['custom:hasOnboarded'].includes('portaltutorialcompleted');
      if (!tutorialCompleted) {
        setTutorialRun(true);
      }
    }
  }, [userAttrs]);

  

  // Mark tutorial complete
  async function markPortalTutorialComplete() {
    try {
      const existingAttr = userAttrs['custom:hasOnboarded'] || ''; 
      if (!existingAttr.includes('portaltutorialcompleted')) {
        const newValue = existingAttr + ',portaltutorialcompleted';
        await updateUserAttributes({
          userAttributes: {
            'custom:hasOnboarded': newValue,
          },
        });
      }
    } catch (err) {
      console.error("Failed to update user's Portal tutorial completion:", err);
    }
  }

  async function goToOrderForm(){
      const existingAttr = userAttrs['custom:hasOnboarded'] || ''; 
      if (!existingAttr.includes('portaltutorialcompleted')) {
        const newValue = existingAttr + ',portaltutorialcompleted';
        try {
          await updateUserAttributes({
            userAttributes: {
              'custom:hasOnboarded': newValue,
            },
          });
        } catch(err) {
          console.log("caught exception:", err)
        } finally {
          refreshUser();
          navigate('/order-form');
        }
      } else {
        navigate('/order-form');
      }
  }

  function handleJoyrideCallback(data) {
    const { action, index, status, type, step } = data;

    if (type === EVENTS.TARGET_NOT_FOUND) {
      if (index + 1 < tutorialSteps.length) {
        if (action === ACTIONS.NEXT) {
          setStepIndex(index + 1);
        } else if (action === ACTIONS.PREV) {
          setStepIndex(index - 1);
        }
      } else {
        setTutorialRun(false);
        setStepIndex(0);
      }
      return;
    }

    if (type === EVENTS.STEP_BEFORE && step?.target) {
      const el = document.querySelector(step.target);
      if (el) {
        const rect = el.getBoundingClientRect();
        const elementCenter = rect.top + window.scrollY - window.innerHeight / 2 + rect.height / 2;
        const scrollPosition = elementCenter - HEADER_OFFSET;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setTutorialRun(false);
      setStepIndex(0);
      markPortalTutorialComplete();
    }
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
          <p className="mt-4 text-lg text-gray-600">Loading user...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="text-center mt-8">
        <p>User not logged in. Please log in first.</p>
      </div>
    );
  }

  // --------------------------------------
  // MAIN RETURN
  // --------------------------------------
  return (
    <div className="container mx-auto px-4 py-8">
      {/* JOYRIDE COMPONENT */}
      <Joyride
        steps={tutorialSteps}
        run={tutorialRun}
        stepIndex={stepIndex}
        tooltipComponent={MyCustomTooltip}
        continuous
        disableOverlayClose
        disableScrolling
        showSkipButton
        showProgress
        spotlightClicks
        callback={handleJoyrideCallback}
        styles={{
          buttonBack: { display: 'none' },
          buttonNext: { display: 'none' },
          options: {
            primaryColor: '#000006',
            zIndex: 998,
            overlayColor: 'rgba(0,0,0,0.0)'
          },
          spotlight: {
            borderRadius: 6,
            boxShadow: '0 0 0 9999px rgba(0,0,0,0.6)',
            backgroundColor: 'transparent',
          },
        }}
      />

      {/* TOP BAR: Project Dashboard on left, "Go to Order Form" button on right */}
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold">Project Dashboard</h1>

        <button
          id="tutorial-order-form-button"
          onClick={goToOrderForm}
          className="px-4 py-2 font-semibold"
          style={{ borderWidth: '5px', border: 'solid', borderRadius: '25px', backgroundColor: 'black', color: 'white' }}
        >
          Upload New Design
        </button>
      </div>

      {loadingOrders && <p>Loading orders...</p>}
      {ordersError && <p style={{ color: 'red' }}>{ordersError.message}</p>}
      {!loadingOrders && !ordersError && orders.length === 0 && (
        <p>
          No uploaded designs found.{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              goToOrderForm();
            }}
            style={{ color: "black", textDecoration: "underline", cursor: "pointer", fontWeight: 'bold' }}
          >
            Upload a new design by clicking here!
          </a>
        </p>
      )}

      {/* GRID of cards */}
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {orders.map((o) => {
          // We'll show a thumbnail from o.designPath if it’s an image
          // We also show the project name & short snippet of designSummary
          const summarySnippet = o.designSummary
            ? o.designSummary.slice(0, 100) + (o.designSummary.length > 100 ? '...' : '')
            : '';

          return (
            <div key={o.id} className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
              {/* Thumbnail */}
              <div className="h-48 w-full bg-gray-100">
                {o.designPath && (
                  <img
                    src={"https://evolverflowamplifystorageefdd9-dev.s3.us-east-2.amazonaws.com/" + o.designPath}
                    alt="Design thumbnail"
                    className="h-full w-full object-cover"
                  />
                )}
              </div>

              <div className="p-4 flex flex-col flex-grow">
                {/* Project Name in Bold */}
                <h2 className="font-bold text-lg mb-1">{o.projectName}</h2>
                {/* Short snippet of designSummary */}
                <p className="text-gray-600 mb-2">
                  {summarySnippet}
                </p>

                {/* Footer row: "Show Details" button + status label */}
                <div className="mt-auto flex items-center justify-between">
                  <button
                    onClick={() => setSelectedOrder(o)}
                    className="text-blue-500 underline"
                  >
                    Show Details
                  </button>
                  {/* Status label */}
                  <span className="ml-2 px-2 py-1 text-sm text-white bg-gray-700 rounded" style={{fontWeight: 'bold'}}>
                    {o.status || 'unknown'}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* MODAL for “Show Details” */}
      {selectedOrder && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          style={{backgroundColor: 'rgba(0,0,0,0.5)'}}
          onClick={() => setSelectedOrder(null)}
        >
          <div
            className="bg-white rounded p-6 w-full max-w-2xl relative"
            style={{backgroundColor: '#ffffff'}}
            onClick={(e) => e.stopPropagation()} // prevent closing when clicking inside
          >
            <button
              onClick={() => setSelectedOrder(null)}
              className="absolute top-2 right-3 text-black-500 hover:text-gray-700"
            >
              <i className="fas fa-times"></i>
            </button>

            <h2 className="text-2xl font-bold mb-4">{selectedOrder.projectName}</h2>
            <p className="text-gray-700 mb-2">
              <strong>Company:</strong> {selectedOrder.companyName}
            </p>
            <p className="text-gray-700 mb-2">
              <strong>Design Summary:</strong> {selectedOrder.designSummary}
            </p>
            <p className="text-gray-700 mb-2">
              <strong>Payment:</strong> {selectedOrder.selectedPayment} 
              {selectedOrder.cost ? ` ($${selectedOrder.cost})` : ''}
            </p>
            <p className="text-gray-700 mb-2">
              <strong>Status:</strong> {selectedOrder.status}
            </p>

            {/* Show angles if any */}
            {Array.isArray(selectedOrder.angles) && selectedOrder.angles.length > 0 && (
              <div className="mb-2">
                <strong>Angle Files:</strong>
                <ul className="list-disc list-inside">
                  
                {selectedOrder.angles.map((anglePath, idx) => (
                  <li key={idx} className="flex items-center mb-1">
                    {/* Download icon/button */}
                    <button
                      onClick={async (e) => {
                        e.preventDefault();
                        try {
                          // 1) Generate a presigned URL for the private object
                          const getUrlResult = await getUrl({
                            path: anglePath, // e.g. "private/..."
                            options: {
                              // optional config:
                              // expiresIn: 900, // 15 minutes
                            },
                          });

                          const { url } = getUrlResult;
                          // 2) Open or download
                          window.open(url, '_blank');
                        } catch (err) {
                          console.error('Failed to get presigned URL:', err);
                          alert('Download failed. Check console.');
                        }
                      }}
                      className="text-blue-500 mr-2 hover:text-blue-700" // styling for the icon
                    >
                      {/* Font Awesome Download Icon */}
                      <i className="fas fa-download"></i>
                    </button>

                    {/* Plain file name (not clickable) */}
                    <span className="text-gray-700">
                      {anglePath.split('/').pop()}
                    </span>
                  </li>
                ))}

                </ul>
              </div>
            )}

            {/* Show design path link if present */}
            <div className="mb-2">
                <strong>Design File:</strong>
                <ul className="list-disc list-inside">
                  {selectedOrder.designPath && (
                    <div className="mb-2 flex items-center">
                      {/* Download icon button */}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          // We'll open the S3 link in a new tab
                          const s3Url =
                            "https://evolverflowamplifystorageefdd9-dev.s3.us-east-2.amazonaws.com/" +
                            selectedOrder.designPath;
                          window.open(s3Url, "_blank");
                        }}
                        className="text-blue-500 hover:text-blue-700 mr-2"
                      >
                        <i className="fas fa-download"></i>
                      </button>

                      {/* File name as plain text (not clickable) */}
                      <span style={{ wordBreak: "break-all" }}>
                        {selectedOrder.designPath.split("/").pop()}
                      </span>
                    </div>
                  )}
                  </ul>
          </div>
          </div>
        </div>
      )}
    </div>
  );
}
