// src/pages/subscriptions.jsx
import React, { useState } from 'react';
import { updateUserAttributes } from '@aws-amplify/auth';
import { useUser } from '../hooks/useUser';
const SUBSCRIPTIONS = [
  { id: 'design_snapshot', name: 'Design Snapshot', originalPrice: 0, discount: 0.5 },
  { id: '2d_renderings', name: '2D Renderings - PhotoComps', originalPrice: 100, discount: 0 },
  { id: '3d_renderings', name: '3D Renderings', originalPrice: 250, discount: 0 },
];

const Subscriptions = () => {
  // 1) Grab user & userAttrs from the context
  const { user, userAttrs, loading } = useUser();

  // We track if user has design snapshot
  const hasDesignSnapshot = userAttrs?.['custom:hasDesignSnapshot'] === 'true';

  // Local state for coupon and selected sub
  const [coupon, setCoupon] = useState('');
  const [selectedSub, setSelectedSub] = useState(null);
  const [error, setError] = useState('');

  // 2) While user context is still loading
  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="text-center">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent"></div>
          <p className="mt-2">Loading subscriptions...</p>
        </div>
      </div>
    );
  }

  // 3) If user not present, or user is null, prompt to log in
  if (!user) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <h2 className="text-2xl font-bold">Please log in to view subscriptions</h2>
      </div>
    );
  }

  // 4) Validate coupon logic
  const validateCoupon = (code, basePrice) => {
    if (code === 'FREE50') return basePrice * 0.5;
    if (code === 'FREE100') return 0;
    return basePrice;
  };

  // 5) Handle buying a sub
  const handleBuy = async (sub) => {
    setError('');
    const basePrice = sub.originalPrice * (1 - sub.discount);
    const finalPrice = validateCoupon(coupon, basePrice);
    setSelectedSub({ ...sub, finalPrice });

    try {
      // If user tries to purchase design_snapshot
      if (sub.id === 'design_snapshot') {
        // If user already has design snapshot
        if (hasDesignSnapshot) {
          alert('You already have Design Snapshot!');
          return;
        }
        // Otherwise, update user attribute
        try {
          await updateUserAttributes({
            userAttributes: {
              'custom:hasDesignSnapshot': 'true',
            },
          });
          alert('Design Snapshot purchased successfully!');
        } catch (error) {
          console.error(error);
          setError('Error updating user attributes after purchase.');
        }
      } else {
        // Other subscriptions...
        alert(`${sub.name} purchased successfully!`);
      }
    } catch (err) {
      console.error(err);
      setError('Error purchasing subscription.');
    }
  };

  // 6) Render the subscription list
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold mb-4">Subscriptions</h1>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Coupon Code</label>
        <input
          type="text"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>

      <div className="grid grid-cols-1 gap-4">
        {SUBSCRIPTIONS.map((sub) => {
          const basePrice = sub.originalPrice * (1 - sub.discount);
          const discounted = sub.discount > 0;

          // If it's design_snapshot & user already has it
          if (sub.id === 'design_snapshot' && hasDesignSnapshot) {
            return (
              <div key={sub.id} className="border p-4 rounded-md">
                <h2 className="text-xl font-bold">{sub.name}</h2>
                <p className="text-green-500 font-semibold">Already Active</p>
              </div>
            );
          }

          // Otherwise, show normal purchase block
          return (
            <div key={sub.id} className="border p-4 rounded-md">
              <h2 className="text-xl font-bold">{sub.name}</h2>
              <p>
                Price:{' '}
                {discounted && (
                  <span className="line-through text-gray-500 mr-2">
                    ${sub.originalPrice}
                  </span>
                )}
                <span className="text-green-600 font-bold">${basePrice}</span>
              </p>
              <button
                className="px-4 py-2 mt-2 font-semibold text-white bg-blue-500 rounded hover:bg-blue-600"
                onClick={() => handleBuy(sub)}
              >
                Buy
              </button>
            </div>
          );
        })}
      </div>

      {selectedSub && (
        <div className="mt-4 w-full max-w-md p-4 border rounded-md">
          <h3 className="text-lg font-bold mb-2">Payment</h3>
          <p className="mb-2">
            You are about to purchase {selectedSub.name} for $
            {selectedSub.finalPrice}.
          </p>
          {error && <p className="text-red-500 mb-2">{error}</p>}
          {/* Payment integration (PayPal, credit card, etc.) could go here */}
        </div>
      )}
    </div>
  );
};

export default Subscriptions;
