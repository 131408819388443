// src/pages/MeetingPage.jsx
import React, { useState, useEffect } from "react";
import SchedulingMeeting from "../components/SchedulingMeeting";
import MeetingLink from "../components/MeetingLink";
import "../css/MeetingsPage.css";
import { useUser } from "../hooks/useUser";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { updateUserAttributes } from "@aws-amplify/auth";

export default function MeetingPage() {
  const { user, userAttrs, refreshUser } = useUser();

  const timeRN = Date.now();

  function MyCustomTooltip(props: TooltipProps) {
    const {
      // Built-in props from Joyride
      step,
      isLastStep,
      primaryProps, // onClick & text for the "Next" or "Finish" button
      backProps,    // onClick & text for the "Back" button
      closeProps,   // onClick & text for the "Close" / "Skip" button
  
      // You can also read `content`, `title`, etc. from `step`
      // if you want to customize them further.
    } = props;

    return (
      <div style={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: 10,
        padding: '1rem',
        maxWidth: 300,
        position: 'relative'
      }}>
        {/* If the step has a title, show it */}
        {step.title && <h3 style={{ marginTop: 0, textAlign: 'center', fontWeight:'500', fontSize:'1.2rem', marginBottom: '0.3rem' }}>{step.title}</h3>}
  
        {/* The main step content */}
        <p>{step.content}</p>
  
        {/* Custom "Back" and "Next/Finish" buttons */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem'}}>
          {/* The "Back" button (only if not the first step, etc.) */}
          {stepIndex !== 0 && (
            <button
              // {...backProps} // merges the built-in logic from Joyride
              onClick={handleTutorialPrevStep}
              style={{ padding: '0.5rem 1rem', backgroundColor: '#000006', color: 'white', borderRadius: '25px', textAlign: 'center'  }}
            >
              Back
            </button>
          )}

          {/* "Skip" or "Close" button, which uses Joyride's built-in skip/close logic */}
        {!isLastStep && (
          <button
          {...closeProps} // merges Joyride's built-in skip/close logic
          onClick={handleTutorialSkip}
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#ccc',
            color: '#333',
            borderRadius: '25px'
          }}
        >
          Skip
        </button>
        )}
        
  
          {/* "Next" or "Finish" button if it's the last step */}
          <button
            // {...(isLastStep ? closeProps : primaryProps)}
            onClick={handleTutorialNextStep}
            style={{ padding: '0.5rem 1rem', backgroundColor: '#000006', color: 'white', borderRadius: '25px', textAlign: 'center'  }}
          >
            {isLastStep ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    );
  }

  // A fallback user name:
  const userName = userAttrs?.name?.replace(/\s+/g, "") || "Guest";
  // Room name for Jitsi
  const jitsiRoomName = `realityfusion3d19334583${userName}`;

  // const [showInstantLink, setShowInstantLink] = useState(false);

  const jitsiUrl = `https://meet.jit.si/${jitsiRoomName}`;

  const [tutorialRun, setTutorialRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  // Example steps: highlight "Start Instant Meeting" and "Scheduling Meeting" button
  const tutorialSteps = [
    {
      target: "#tutorial-instant-meeting",
      title: "Instant Meeting",
      content: 'You can click here to start an instant meeting right now! This requests a 1-1 meeting with our customer support agent and our representative will be with you shortly.',
      disableBeacon: true,
    },
    {
      target: "#tutorial-schedule-meeting",
      title: "Schedule a Meeting",
      content: "Or you can choose a date and time to schedule a meeting for later!",
    },
  ];

  // If you have a sticky header, define an offset so we scroll properly
  const HEADER_OFFSET = 60;

  useEffect(() => {
    if (userAttrs && userAttrs["custom:hasOnboarded"]) {
      const hasOnboarded = userAttrs["custom:hasOnboarded"];
      // Check if the user already completed the "meetingpagetutorial"
      if (!hasOnboarded.includes("meetingpagetutorialcompleted")) {
        setTutorialRun(true); // auto-run the tutorial
      }
    }
  }, [userAttrs]);

  function handleTutorialNextStep(){
    if(tutorialRun) {
      setStepIndex(stepIndex + 1)

    }
  }

  function handleTutorialPrevStep(){
    setStepIndex(stepIndex - 1)
  }

  function handleTutorialSkip(){
    setStepIndex(0)
    setTutorialRun(false)
  }

    // Mark tutorial as complete in the user's Cognito attributes
    async function markMeetingTutorialComplete() {
      try {
        const existingAttr = userAttrs["custom:hasOnboarded"] || "";
        if (!existingAttr.includes("meetingpagetutorialcompleted")) {
          const newValue = existingAttr + ",meetingpagetutorialcompleted";
          await updateUserAttributes({
            userAttributes: {
              "custom:hasOnboarded": newValue,
            },
          });
          refreshUser?.();
        }
      } catch (err) {
        console.error("Error updating user attribute for meeting tutorial:", err);
      }
    }
  
    function handleJoyrideCallback(data) {
      const { status, type, index, step } = data;
  
      // If Joyride can't find the target => skip to the next step or end
      if (type === EVENTS.TARGET_NOT_FOUND) {
        if (index + 1 < tutorialSteps.length) {
          setStepIndex(index + 1);
        } else {
          setTutorialRun(false);
          setStepIndex(0);
        }
        return;
      }
  
      // Smooth-scroll to the element
      if (type === "step:before" && step?.target) {
        const el = document.querySelector(step.target);
        if (el) {
          const rect = el.getBoundingClientRect();
          const elementCenter =
            rect.top + window.scrollY - window.innerHeight / 2 + rect.height / 2;
          const scrollPosition = elementCenter - HEADER_OFFSET;
          window.scrollTo({ top: scrollPosition, behavior: "smooth" });
        }
      }
  
      // If user finishes or skips
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        setTutorialRun(false);
        setStepIndex(0);
        markMeetingTutorialComplete();
      }
    }

  
  async function handleMeetingEmail() {
    
    // Prepare Mailjet data
    const subject = `Instant Meeting Requested By ${userName}`;
    const textBody = `
      Instant Meeting is requested by ${userName}
      Please join using this Jitsi link: ${jitsiUrl}
    `;

    // You must have your Mailjet public/private keys. Typically done server-side,
    // but for demonstration here we do a direct call from the front-end.
    const mailjetApiUrl = 'https://api.mailjet.com/v3.1/send';
    // example only — do not expose your real keys in client code
    const mailjetApiKey = process.env.MAILJET_API_KEY;
    const mailjetApiSecret = process.env.MAILJET_API_SECRET;

    const mailjetAuth = btoa(`${mailjetApiKey}:${mailjetApiSecret}`);

    const mailjetPayload = {
      Messages: [
        {
          From: {
            Email: 'syedosamaaskari@gmail.com',
            Name: 'Meetings Robot',
          },
          To: [
            {
              Email: 'support@evolverinteractive.com',
              Name: 'Support Team',
            },
          ],
          Subject: subject,
          TextPart: textBody,
        },
      ],
    };

    try {
      const response = await fetch(mailjetApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${mailjetAuth}`,
        },
        body: JSON.stringify(mailjetPayload),
      });

      if (!response.ok) {
        const respText = await response.text();
        throw new Error(`Mailjet error: ${respText}`);
      }

      // setSuccessMsg(`Meeting Link: ${jitsiUrl}`);
    } catch (err) {
      console.error('Mailjet request failed:', err);
      // setError('Failed to send meeting request. Please try again later.');
    }
  }

  function handleOpenMeeting() {
    handleMeetingEmail();
    window.open(jitsiUrl, "_blank", "noopener,noreferrer");
    handleTutorialNextStep();

  }

  // function handleInstantMeeting() {
  //   setShowInstantLink(true);
  // }

  return (
    <div className="meetings-page-container">

      {/* Joyride component */}
      <Joyride
        steps={tutorialSteps}
        run={tutorialRun}
        stepIndex={stepIndex}
        continuous
        tooltipComponent={MyCustomTooltip}
        callback={handleJoyrideCallback}
        showSkipButton
        showProgress
        spotlightClicks
        scrollToFirstStep
        disableOverlayClose
        disableScrolling
        styles={{
          options: {
            zIndex: 9999,
            primaryColor: "#000",
            overlayColor: "rgba(0,0,0,0)",
          },
          spotlight: {
            borderRadius: 6,
            boxShadow: "0 0 0 9999px rgba(0,0,0,0.6)",
            backgroundColor: "transparent",
          },
        }}
      />

      <h1 className="meetings-title">Support Meeting Options</h1>
      <div className="meetings-action-buttons">

          <>
            <button className="meetings-button" id="tutorial-instant-meeting" onClick={handleOpenMeeting}>
              Start Instant Meeting
            </button>
            <div id="tutorial-schedule-meeting">
            <SchedulingMeeting />
            </div>
          </>
         
          
      </div>
    </div>
  );
}
