// Confetti.jsx
import React from 'react';
import '../css/Confetti.css';

export default function Confetti() {
  // Suppose we want 20 pieces
  const confettiCount = 80;
  const confettiPieces = Array.from({ length: confettiCount }, (_, i) => i);

  return (
    <div className="confetti-container">
      {confettiPieces.map((_, i) => {
        const randomLeft = Math.random() * 100;   // 0..100%
        const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16); 

        return (
            <div
            key={i}
            className="confetti-piece"
            style={{
                left: `${randomLeft}%`,
                backgroundColor: randomColor,
                animationDelay: `${Math.random() * 1.5}s`,  // small offset
            }}
            />
        );
        })}
    </div>
  );
}
