import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getCurrentUser, fetchUserAttributes, signOut } from '@aws-amplify/auth';

import { client } from '../App';
import { listOrders } from '../graphql/queries';

export const UserContext = createContext(null);

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userAttrs, setUserAttrs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [ordersError, setOrdersError] = useState(null);

  // 1) The function that loads user data once
  const loadUser = useCallback(async () => {
    
    setLoading(true);
    setError(null);
    try {
      const cUser = await getCurrentUser();
      console.log("loadUserCalled")
      if (!cUser) {
        setUser(null);
        setUserAttrs(null);
      } else {
        setUser(cUser);
        const attrs = await fetchUserAttributes(cUser);
        setUserAttrs(attrs);
      }
    } catch (err) {
      setUser(null);
      setUserAttrs(null);
      setError(err.message || 'Failed to load user');
    } finally {
      setLoading(false);
    }
  }, []);

  // 2) Effect that runs exactly once, on provider mount
  useEffect(() => {
    loadUser(); // Called once. We do NOT put [loadUser] or other variables in the deps array
  }, []);       // because that would re-run whenever those changed.

  // 3) If you want to manually force a refresh, expose it:
  const refreshUser = useCallback(() => {
    loadUser();
  }, [loadUser]);


  // ---------------------------
  //  D) Load orders (once user is known)
  // ---------------------------
  const loadOrders = useCallback(async () => {
    // If no user, skip or reset
    if (!user) {
      setOrders([]);
      setLoadingOrders(false);
      return;
    }

    setLoadingOrders(true);
    setOrdersError(null);

    try {
      const result = await client.graphql({ query: listOrders });
      const items = result?.data?.listOrders?.items || [];
      setOrders(items);
    } catch (err) {
      setOrdersError(err);
    } finally {
      setLoadingOrders(false);
    }
  }, [user]);

  // On mount or whenever `user` changes
  useEffect(() => {
    if (user) {
      loadOrders();
    } else {
      // if user logs out or is null, reset orders
      setOrders([]);
      setLoadingOrders(false);
    }
  }, [user, loadOrders]);

  // A manual function to refresh orders
  const refreshOrders = useCallback(() => {
    loadOrders();
  }, [loadOrders]);


  // 4) Helper to sign out
  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
      setUserAttrs(null);
    } catch (err) {
      setError(err.message || 'Failed to sign out');
    }
  };

  const value = {
    user,
    userAttrs,
    loading,
    error,
    refreshUser,   // For any future manual refresh
    handleSignOut,

    orders,
    loadingOrders,
    ordersError,
    refreshOrders,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}
