/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://vljcagkb7zfqlmucei67o2vql4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-lmu2pbfufndcphk3ocjyi6iswm",
    "aws_cognito_identity_pool_id": "us-east-2:59bb38e5-0068-4385-95bd-82996948d54b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_nziCJEYDE",
    "aws_user_pools_web_client_id": "ue26s51tdvtepb99mv08gc6dn",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "evolverflowamplifystorageefdd9-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
