// src/pages/OnboardingPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { updateUserAttributes } from '@aws-amplify/auth';

import '../css/OnboardingPage.css';

export default function OnboardingPage() {
  const navigate = useNavigate();
  const { user, userAttrs, userLoading, refreshUser } = useUser();

  const [step, setStep] = useState(0);

  // STEP 1: Phone Number (Pre-fill with +1)
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const [phoneError, setPhoneError] = useState('');

  // STEP 2: Service Preferences (multiple checkboxes)
  const [servicePrefs, setServicePrefs] = useState({
    digitalTwin: false,
    visualization: false,
    snapshot: false,
    other: false,
  });
  // If "other" is checked, user can type freeform
  const [servicePrefOtherText, setServicePrefOtherText] = useState('');

  // Are we finalizing / saving at the end?
  const [saving, setSaving] = useState(false);

  // ------------------------------------------------------------------
  // 1) If user is not logged in (and not loading) => go login.
  //    If user is onboarded already => skip to /order-form
  // ------------------------------------------------------------------
  useEffect(() => {
    // If still loading, do nothing yet
    if (userLoading) return;

    // If no user, redirect
    if (!user) {
      navigate('/');
      return;
    }

    // If userAttrs not ready or doesn't exist, do nothing yet
    if (!userAttrs) return;

    // Now userAttrs is safe to use
    const existingAttr = userAttrs['custom:hasOnboarded'] || '';

    // If user is onboarded, skip to /order-form
    if (existingAttr.includes('true')) {
      navigate('/order-form');
    }
  }, [user, userAttrs, userLoading, navigate]);

  // ------------------------------------------------------------------
  // STEP 0 Validation: phone number
  // ------------------------------------------------------------------
  function validateStep0() {
    if (!phoneNumber.trim()) {
      setPhoneError('Please enter a phone number.');
      return false;
    }
    // Ensure it matches the pattern: optional +, then digits only
    const phonePattern = /^\+?\d*$/;
    if (!phonePattern.test(phoneNumber)) {
      setPhoneError(
        'Phone number can only contain digits (0-9) and an optional + sign.'
      );
      return false;
    }
    // Check at least 10 digits (strip out the plus if present)
    const digitCount = phoneNumber.replace(/\D/g, '').length;
    if (digitCount < 10) {
      setPhoneError(
        'Please check your number. Is it too short? (At least 10 digits)'
      );
      return false;
    }
    // Clear error if valid
    setPhoneError('');
    return true;
  }

  // ------------------------------------------------------------------
  // STEP 1 Validation: service preferences
  // At least one must be selected. If "other" is selected, user must fill text.
  // ------------------------------------------------------------------
  function validateStep1() {
    const isAnythingChecked = Object.values(servicePrefs).some(
      (val) => val === true
    );
    if (!isAnythingChecked) {
      alert('Please select at least one Service Preference.');
      return false;
    }
    // If "other" is checked but user left the text blank
    if (servicePrefs.other && !servicePrefOtherText.trim()) {
      alert('Please specify your other service preference.');
      return false;
    }
    return true;
  }

  // ------------------------------------------------------------------
  // STEP NAVIGATION
  // ------------------------------------------------------------------
  function nextStep() {
    if (step === 0) {
      if (!validateStep0()) return;
      setStep(1);
    } else if (step === 1) {
      if (!validateStep1()) return;
      handleFinish();
    }
  }

  // ------------------------------------------------------------------
  // FINAL SUBMISSION (Now after Step 1)
  // ------------------------------------------------------------------
  async function handleFinish() {
    setSaving(true);
    try {
      // Collate service prefs
      const selectedServices = [];
      if (servicePrefs.digitalTwin) selectedServices.push('Digital Twin');
      if (servicePrefs.visualization) selectedServices.push('Visualization');
      if (servicePrefs.snapshot) selectedServices.push('Snapshot');
      if (servicePrefs.other) {
        selectedServices.push(`Other(${servicePrefOtherText})`);
      }
      const finalServicePref = selectedServices.join(', ');

      // Update user attributes
      const existingAttr = userAttrs['custom:hasOnboarded'] || '';
      await updateUserAttributes({
        userAttributes: {
          'custom:hasOnboarded': existingAttr + ',true',
          phone_number: phoneNumber,
          'custom:servicePref': finalServicePref,
        },
      });

      refreshUser();
    } catch (err) {
      console.error('Error updating attributes:', err);
      alert('Failed to update onboarding info. Check console.');
    } finally {
      setSaving(false);
      navigate('/order-form');
    }
  }

  // ------------------------------------------------------------------
  // If user context is still loading
  // ------------------------------------------------------------------
  if (userLoading) {
    return (
      <div className="onboarding-container">
        <div className="onboarding-loader">
          <div className="spinner" />
          <p>Loading onboarding...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="onboarding-container">
      <div className="air air1" />
      <div className="air air2" />
      <div className="air air3" />
      <div className="air air4" />

      <img
        src="/assets/RF3d_logo.png"
        alt="Reality Fusion 3D"
        className="onboarding-logo"
      />

      <div className="onboarding-step-card fade-in">
        <h2 className="onboarding-title">
          <span style={{ color: '#3a3a3a' }}>Welcome Aboard </span>
          {user ? (
            <span>, {userAttrs['name']}!</span>
          ) : (
            <>!</>
          )}
        </h2>

        {/* STEP 0: PHONE NUMBER */}
        {step === 0 && (
          <div className="onboarding-step-content">
            <label className="onboarding-label">Phone Number</label>
            <input
              type="text"
              className="onboarding-input"
              value={phoneNumber}
              onChange={(e) => {
                const val = e.target.value;
                // Only allow + and digits
                if (val.length <= 2000) {
                  const phonePattern = /^\+?\d*$/;
                  if (phonePattern.test(val)) {
                    setPhoneNumber(val);
                  }
                }
              }}
              maxLength={2000}
            />
            {phoneError && (
              <p
                style={{
                  color: 'red',
                  marginTop: '-10px',
                  fontSize: '0.9rem',
                }}
              >
                {phoneError}
              </p>
            )}

            <button className="onboarding-button" onClick={nextStep}>
              Next
            </button>
          </div>
        )}

        {/* STEP 1: SERVICE PREFS (CHECKBOXES) */}
        {step === 1 && (
          <div className="onboarding-step-content fade-in">
            <label className="onboarding-label">Service Preferences</label>

            <div style={{ textAlign: 'left', lineHeight: '1.8' }}>
              <label>
                <input
                  type="checkbox"
                  checked={servicePrefs.digitalTwin}
                  onChange={() =>
                    setServicePrefs((prev) => ({
                      ...prev,
                      digitalTwin: !prev.digitalTwin,
                    }))
                  }
                />{' '}
                Digital Twin
              </label>
              <br />

              <label>
                <input
                  type="checkbox"
                  checked={servicePrefs.visualization}
                  onChange={() =>
                    setServicePrefs((prev) => ({
                      ...prev,
                      visualization: !prev.visualization,
                    }))
                  }
                />{' '}
                Visualization
              </label>
              <br />

              <label>
                <input
                  type="checkbox"
                  checked={servicePrefs.snapshot}
                  onChange={() =>
                    setServicePrefs((prev) => ({
                      ...prev,
                      snapshot: !prev.snapshot,
                    }))
                  }
                />{' '}
                Snapshot
              </label>
              <br />

              <label>
                <input
                  type="checkbox"
                  checked={servicePrefs.other}
                  onChange={() =>
                    setServicePrefs((prev) => ({
                      ...prev,
                      other: !prev.other,
                    }))
                  }
                />{' '}
                Custom Services
              </label>

              {/* If other is checked, show text input */}
              {servicePrefs.other && (
                <div
                  className="onboarding-others-input-field-container"
                  style={{ marginTop: '0.5rem' }}
                >
                  <input
                    type="text"
                    className="onboarding-input"
                    placeholder="Please specify"
                    value={servicePrefOtherText}
                    onChange={(e) => {
                      if (e.target.value.length <= 1900) {
                        setServicePrefOtherText(e.target.value);
                      }
                    }}
                    maxLength={2000}
                  />
                </div>
              )}
            </div>

            <button
              className="onboarding-button"
              onClick={nextStep}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Finish'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
