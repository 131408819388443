import React, { useState } from "react";
import "../css/ScheduleMeeting.css"; // your styling
import { useUser } from "../hooks/useUser";

export default function SchedulingMeeting() {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(""); 
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");

  const { userAttrs } = useUser();
  const userDisplayName = (userAttrs?.name ?? "AnonymousUser").replace(/\s+/g, "");

  function getHalfHourSlots() {
    const slots = [];
    for (let hour = 9; hour < 17; hour++) {
      const hourStr = hour.toString().padStart(2, "0");
      slots.push(`${hourStr}:00`);
      slots.push(`${hourStr}:30`);
    }
    return slots;
  }

  const halfHourSlots = getHalfHourSlots();

  // Example: Monday–Friday, 9am–5pm
  function isWeekday(dateObj) {
    const day = dateObj.getDay(); // 0 = Sunday, 1 = Monday ...
    return day >= 1 && day <= 5;
  }

  // Build the Jitsi Link
  function createScheduledJitsiLink() {
    const ts = Date.now();
    return `https://meet.jit.si/realityfusion3d${userDisplayName}${ts}`;
  }

  async function handleSchedule(e) {
    e.preventDefault();
    setError(null);
    setSuccessMsg("");

    // (A) Basic checks
    if (!selectedDate) {
      setError("Please choose a valid date.");
      return;
    }
    if (!selectedTimeSlot) {
      setError("Please select a timeslot.");
      return;
    }

    // Parse the date, and then combine with the time slot
    // e.g. selectedDate: "2023-12-10", selectedTimeSlot: "09:30"
    const [year, month, day] = selectedDate.split("-"); 
    const [hh, mm] = selectedTimeSlot.split(":");

    const dateObj = new Date(
      Number(year),
      Number(month) - 1, 
      Number(day),
      Number(hh),
      Number(mm),
      0
    );

    // (B) Must be a weekday
    if (!isWeekday(dateObj)) {
      setError("Please choose a weekday (Mon–Fri).");
      return;
    }

    // (C) Confirm the hour is in [9..17)
    const hour = dateObj.getHours();
    if (hour < 9 || hour >= 17) {
      setError("Please choose a timeslot between 9am and 5pm only.");
      return;
    }

    // (D) Build Jitsi link & send email
    const jitsiLink = createScheduledJitsiLink();

    
    // Prepare Mailjet data
    const subject = 'Scheduled Meeting Request';
    const textBody = `
      A meeting has been scheduled for:
      ${dateObj.toString()} \n
      Please join using this Jitsi link: ${jitsiLink}
    `;

    // You must have your Mailjet public/private keys. Typically done server-side,
    // but for demonstration here we do a direct call from the front-end.
    const mailjetApiUrl = 'https://api.mailjet.com/v3.1/send';
    // example only — do not expose your real keys in client code
    const mailjetApiKey = process.env.MAILJET_API_KEY;
    const mailjetApiSecret = process.env.MAILJET_API_SECRET;

    const mailjetAuth = btoa(`${mailjetApiKey}:${mailjetApiSecret}`);

    const mailjetPayload = {
      Messages: [
        {
          From: {
            Email: 'syedosamaaskari@gmail.com',
            Name: 'Meetings Robot',
          },
          To: [
            {
              Email: 'support@evolverinteractive.com',
              Name: 'Support Team',
            },
          ],
          Subject: subject,
          TextPart: textBody,
        },
      ],
    };

    try {
      const response = await fetch(mailjetApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${mailjetAuth}`,
        },
        body: JSON.stringify(mailjetPayload),
      });

      if (!response.ok) {
        const respText = await response.text();
        throw new Error(`Mailjet error: ${respText}`);
      }

      setSuccessMsg(`Meeting scheduled on ${dateObj.toLocaleString()}.\nLink: ${jitsiLink}`);
    } catch (err) {
      console.error('Mailjet request failed:', err);
      setError('Failed to send meeting request. Please try again later.');
    }
  }

  return (
    <div className="schedule-meeting-container">
      <h2 className="schedule-meeting-title">Schedule a Meeting</h2>

      <form className="schedule-meeting-form" onSubmit={handleSchedule}>
        {/* The date input */}
        <label className="schedule-meeting-label">
          Pick a Date (Mon–Fri):
          <input
            type="date"
            className="schedule-meeting-input"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </label>

        {/* The time-slot select */}
        <label className="schedule-meeting-label">
          Time Slot (9am–5pm):
          <select
            className="schedule-meeting-input"
            value={selectedTimeSlot}
            onChange={(e) => setSelectedTimeSlot(e.target.value)}
          >
            <option value="">-- Select a time slot --</option>
            {halfHourSlots.map((slot) => (
              <option key={slot} value={slot}>
                {slot}
              </option>
            ))}
          </select>
        </label>

        {error && <p className="schedule-meeting-error">{error}</p>}
        {successMsg && <p className="schedule-meeting-success">{successMsg}</p>}

        <button className="schedule-meeting-button" type="submit">
          Schedule
        </button>
      </form>
    </div>
  );
}
