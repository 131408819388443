// src/components/SignInModal.jsx
import React, { useState, useEffect } from 'react';
import { signIn, confirmSignUp, resendSignUpCode } from '@aws-amplify/auth';
import { useUser } from '../hooks/useUser';

function SignInModal({ onClose, onSignInSuccess, onSwitchToSignUp }) {
  const [step, setStep] = useState('signIn'); // 'signIn' or 'confirmSignUp'
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // For confirm sign up
  const [confirmationCode, setConfirmationCode] = useState('');

  const [error, setError] = useState('');

  const [showPassword1, setShowPassword1] = useState(false); // toggles primary password field
  const [loading, setLoading] = useState(false);
  const { user, loading: userLoading, refreshUser } = useUser();
  


  // 1) Disable background scroll on mount, re-enable on unmount
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  async function handleSignIn(e) {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      if (isSignedIn) {
        onSignInSuccess?.();
        refreshUser();

      } else if (nextStep?.signInStep === 'CONFIRM_SIGN_UP') {
        // account not confirmed
        setStep('confirmSignUp');
      } else {
        console.log('Unknown nextStep:', nextStep);
      }
    } catch (err) {
      console.error('Error signing in:', err);
      setError(err.message || 'Error signing in');
    } finally {
      setLoading(false);
          }
  }

  async function handleResendCode() {
    try {
      await resendSignUpCode({ username });
      alert('Code resent!');
    } catch (err) {
      console.log('Error resending code:', err);
    }
  }

  async function handleConfirmSignUp(e) {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode,
      });
      if (isSignUpComplete) {
        alert('Account confirmed! Please sign in again.');
        setStep('signIn');
      } else {
        console.log('nextStep =', nextStep);
      }
    } catch (err) {
      console.error('Error confirming sign up:', err);
      setError(err.message || 'Error confirming sign up');
    } finally {
           setLoading(false);
          }
  }

  const togglePasswordVisibility1 = () => {
    setShowPassword1((prev) => !prev);
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="auth-modal-close" onClick={onClose}>
          &times;
        </button>

        {step === 'signIn' && (
          <>
            <div className='auth-modal-title '>
            <img
                src="logo192.png"
                alt="Logo"
                className="shake h-24 z-10 m-3"
              />
              <h2 >Sign In</h2>
            </div>
            
            {error && <p className="auth-modal-error">{error}</p>}
            <form onSubmit={handleSignIn} className="auth-modal-form">
              <div className="auth-form-group">
                <label className="auth-label">Email</label>
                <input
                  className="auth-input"
                  type="text"
                  value={username}
                  placeholder='xyz@company.com'
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="auth-form-group">
                <label className="auth-label">Password</label>
                <input
                  className="auth-input"
                  type={showPassword1 ? 'text' : 'password'}
                  value={password}
                  placeholder='StrongPassword!'
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                    type="button"
                    className="auth-password-toggle"
                    onClick={togglePasswordVisibility1}
                    style={{position: 'absolute', marginTop: '38px'}}
                  >
                    {showPassword1 ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                  </button>
              </div>
              {loading ? (
               <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                 <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                 <p className="mt-2">Signing in...</p>
               </div>
             ) : (
               <button type="submit" className="auth-button">
                 Sign In
               </button>
             )}
            </form>
            <p className="auth-modal-switch">
              Don't have an account?{' '}
              <button className="auth-switch-button" onClick={onSwitchToSignUp}>
                Sign Up
              </button>
            </p>
          </>
        )}

        {step === 'confirmSignUp' && (
          <>
            <h2 className="auth-modal-title">Confirm Sign Up</h2>
            {error && <p className="auth-modal-error">{error}</p>}
            <form onSubmit={handleConfirmSignUp} className="auth-modal-form">
              <p className="auth-modal-text">
                Enter the confirmation code sent to your email or phone for{' '}
                <strong>{username}</strong>
              </p>
              <div className="auth-form-group">
                <label className="auth-label">Confirmation Code</label>
                <input
                  className="auth-input"
                  type="text"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  required
                />
              </div>
              <div className="auth-modal-actions">
              {loading ? (
               <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                 <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                 <p className="mt-2">Confirming...</p>
               </div>
             ) : (
               <button type="submit" className="auth-button">Confirm</button>
             )}
                <button
                  type="button"
                  className="auth-button secondary"
                  onClick={handleResendCode}
                >
                  Resend Code
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default SignInModal;
