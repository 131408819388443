// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsExports from './aws-exports';

// If you have a custom hook & context:
import { UserProvider } from './context/UserContext'; 
import { useUser } from './hooks/useUser';

// Pages and components
import LoginPage from './pages/Login';
import OnboardingPage from './pages/Onboarding';
import OrderForm from './pages/OrderForm';
import Portal from './pages/Portal';
import Subscriptions from './pages/subscriptions';
import Layout from './components/Layout/Layout';
import MeetingsPage from './pages/MeetingsPage';
// ... any other imports

Amplify.configure(awsExports);
export const client = generateClient();


function App() {
  // Get user info from context/hook:
  // userLoading -> whether user is still loading
  // user -> user object if signed in, else null
  const { user, userLoading } = useUser();

  // A small helper to render a spinner or "Loading..." text
  const renderLoading = () => (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
      <p className="mt-2">Loading user info...</p>
    </div>
  );

  return (
      <Router>
        <Routes>

          {/* Public routes (no Layout) */}
          <Route
            path="/"
            element={
              userLoading ? (
                renderLoading()
              ) : user ? (
                <Navigate to="/order-form" />
              ) : (
                <LoginPage />
              )
            }
          />
          {/* <Route path="/" element={<LoginPage />} /> */}
          <Route
            path="/onboarding"
            element={
              userLoading ? (
                renderLoading()
              ) : (
                <OnboardingPage />
                
              )
            }
          />
          {/* <Route path="/onboarding" element={<OnboardingPage />} /> */}

          {/* 
            For each protected route:
            1) If userLoading => show spinner
            2) If no user => <Navigate to="/" />
            3) else => wrap with <Layout> + the component
          */}

          <Route
            path="/order-form"
            element={
              userLoading ? (
                renderLoading()
              ) : user ? (
                <Layout>
                  <OrderForm />
                </Layout>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/portal"
            element={
              userLoading ? (
                renderLoading()
              ) : user ? (
                <Layout>
                  <Portal />
                </Layout>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/meeting"
            element={
              userLoading ? (
                renderLoading()
              ) : user ? (
                <Layout>
                  <MeetingsPage />
                </Layout>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/subscriptions"
            element={
              userLoading ? (
                renderLoading()
              ) : user ? (
                <Layout>
                  <Subscriptions />
                </Layout>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* You can add more routes similarly */}
        </Routes>
      </Router>
  );
}

export default App;
