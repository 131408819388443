import React, { useState, useEffect } from 'react';
import { signUp, confirmSignUp, resendSignUpCode } from '@aws-amplify/auth';

function SignUpModal({ onClose, onSignUpSuccess, onSwitchToSignIn }) {
  const [step, setStep] = useState('signUp'); // "signUp" or "confirm"
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // const [showPassword, setShowPassword] = useState(false); // Toggles password visibility

  const [showPassword1, setShowPassword1] = useState(false); // toggles primary password field
  const [showPassword2, setShowPassword2] = useState(false); // toggles confirm password field


  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [loading, setLoading] = useState(false);


  const [error, setError] = useState('');

  // 1) Disable background scroll on mount, re-enable on unmount
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  async function handleSignUp(e) {
    e.preventDefault();
    setError('');

    // 1. Check if the two passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setLoading(true);

    try {
      const { isSignUpComplete, nextStep } = await signUp({
        username, 
        password,
        options: {
          userAttributes: {
            name,
            'custom:companyName': companyName,
          },
          autoSignIn: false, 
        },
      });

      if (isSignUpComplete) {
        // Possibly user is fully signed up, no confirmation needed
        onSignUpSuccess?.();
      } else if (isSignUpComplete === false) {
        // Typically nextStep?.signUpStep === 'CONFIRM_SIGN_UP'
        if (nextStep?.signUpStep === 'CONFIRM_SIGN_UP') {
          setStep('confirm');
        }
      }
    } catch (err) {
      console.error('Error signing up:', err);
      setError(err.message || 'Error signing up');
    } finally {
           setLoading(false);
          }
  }

  async function handleResendCode() {
    try {
      await resendSignUpCode({ username });
      alert('Code resent!');
    } catch (err) {
      console.log('Error resending code:', err);
    }
  }

  async function handleConfirmSignUp(e) {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode,
      });

      if (isSignUpComplete) {
        onSignUpSuccess?.();
      } else {
        console.log('nextStep =', nextStep);
      }
    } catch (err) {
      console.error('Error confirming sign up:', err);
      setError(err.message || 'Error confirming sign up');
    } finally {
           setLoading(false);
          }
  }

  const togglePasswordVisibility1 = () => {
    setShowPassword1((prev) => !prev);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2((prev) => !prev);
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="auth-modal-close" onClick={onClose}>
          &times;
        </button>

        {step === 'signUp' && (
          <>
            <div className="auth-modal-title">
              <img src="logo192.png" alt="Logo" className="shake h-24 z-10 m-3" />
              <h2>Sign Up</h2>
            </div>
            {error && <p className="auth-modal-error">{error}</p>}

            <form onSubmit={handleSignUp} className="auth-modal-form">
              <div className="auth-form-group">
                <label className="auth-label">Name</label>
                <input
                  className="auth-input"
                  type="text"
                  value={name}
                  placeholder="John Doe"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="auth-form-group">
                <label className="auth-label">Company Name</label>
                <input
                  className="auth-input"
                  type="text"
                  value={companyName}
                  placeholder="XYZ Company Inc."
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>

              <div className="auth-form-group">
                <label className="auth-label">Email</label>
                <input
                  className="auth-input"
                  type="text"
                  value={username}
                  placeholder="xyz@company.com"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>

              <div className="auth-form-group">
                <label className="auth-label">Password</label>
                <input
                  className="auth-input"
                  type={showPassword1 ? 'text' : 'password'}
                  value={password}
                  placeholder="StrongPassword!"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                    type="button"
                    className="auth-password-toggle"
                    onClick={togglePasswordVisibility1}
                    style={{position: 'absolute', marginTop: '38px'}}
                  >
                    {showPassword1 ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                  </button>
              </div>

              <div className="auth-form-group">
                <label className="auth-label">Confirm Password</label>
                <input
                  className="auth-input"
                  type={showPassword2 ? 'text' : 'password'}
                  value={confirmPassword}
                  placeholder="Retype Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                    type="button"
                    className="auth-password-toggle"
                    onClick={togglePasswordVisibility2}
                    style={{position: 'absolute', marginTop: '38px'}}
                  >
                    {showPassword2 ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
                  </button>
              </div>

              

              {loading ? (
               <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                    <p className="mt-2">Signing up...</p>
                  </div>
                ) : (
                  <button type="submit" className="auth-button">Sign Up</button>
                )}
                </form>

            <p className="auth-modal-switch">
              Already have an account?{' '}
              <button className="auth-switch-button" onClick={onSwitchToSignIn}>
                Sign In
              </button>
            </p>
          </>
        )}

        {step === 'confirm' && (
          <>
            <h2 className="auth-modal-title">Confirm Sign Up</h2>
            {error && <p className="auth-modal-error">{error}</p>}
            <form onSubmit={handleConfirmSignUp} className="auth-modal-form">
              <div className="auth-form-group">
                <label className="auth-label">Confirmation Code</label>
                <input
                  className="auth-input"
                  type="text"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  required
                />
              </div>

              <div className="auth-modal-actions">
              {loading ? (
               <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                 <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                 <p className="mt-2">Confirming...</p>
               </div>
             ) : (
               <button type="submit" className="auth-button">Confirm Sign Up</button>
             )}
                <button
                  type="button"
                  className="auth-button secondary"
                  onClick={handleResendCode}
                >
                  Resend Code
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default SignUpModal;
