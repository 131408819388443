// components/FileUploader.jsx
import React, { useState, useRef } from 'react';
import JSZip from 'jszip';
import { uploadData } from '@aws-amplify/storage';
import { OpenAI } from "openai";


const FileUploader = ({ label, onSummaryReceived,  uploadPath, onUploadComplete, isImage = false, projectName, }) => {
  const [processing, setProcessing] = useState(false);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [fileName, setFileName] = useState('')
  const [processVideoToggle, setProcessVideoToggle] = useState(false);
  const fileInputRef = useRef(null);

  const processVideo = async (file) => {
    setProcessing(true);
    setProcessingProgress(0);

    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.crossOrigin = 'anonymous';

    await new Promise((resolve) => (video.onloadedmetadata = resolve));

    const duration = video.duration;
    const fps = 30; 
    const frameInterval = 8; 

    const totalFrames = Math.floor((duration * fps) / frameInterval);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const zip = new JSZip();

    for (let frame = 0; frame < totalFrames; frame++) {
      const time = (frame * frameInterval) / fps;
      video.currentTime = time;

      await new Promise((resolve) => (video.onseeked = resolve));

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL('image/webp', 0.8); 
      const imageData = atob(dataURL.split(',')[1]);
      const uint8Array = new Uint8Array(imageData.length);

      for (let i = 0; i < imageData.length; i++) {
        uint8Array[i] = imageData.charCodeAt(i);
      }

      zip.file(`frame_${frame * frameInterval}.jpg`, uint8Array);

      const progressPercent = Math.round(((frame + 1) / totalFrames) * 100);
      setProcessingProgress(progressPercent);
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    setProcessing(false);
    setProcessingProgress(100);
    return zipBlob;
  };

  function readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result); 
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }


  // ------------------ FUNCTION THAT SUMMARIZES USING BASE64 URL LOCALLY USING CHATGPT o1, DIRECT FILES NOT SUPPORTED -----------------------

  

  async function summarizeLocalImage(file) {
    // Convert the local file -> base64 data URL 
    const base64DataUrl = await readFileAsDataURL(file);
  
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,  // or process.env.REACT_APP_OPENAI_KEY
      dangerouslyAllowBrowser: true,
    });
  
    const modelName = "o1"; // or "gpt-4o"
  
    // Make the chat request
    const response = await openai.chat.completions.create({
      model: modelName,
      messages: [
        {
          role: "user",
          content: [
            { type: "text", text: "What is in this image?" },
            // The crucial part: using the data URL
            {
              type: "image_url",
              image_url: {
                url: base64DataUrl,
              },
            },
          ],
        },
      ],
    });
  
    // Return the text from the assistant
    return response.choices[0].message; 
  }

  // ------------------ FUNCTION THAT SUMMARIZES USING BASE64 URL LOCALLY USING CHATGPT o1, DIRECT FILES NOT SUPPORTED END -----------------------
  

  
  // Summarize the image directly from the frontend
  async function summarizeImageViaOpenAI(imageUrl) {
  // 1) Initialize OpenAI with your API key (be mindful of security).
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true
    // or "sk-..." directly, but environment variables are somewhat safer 
  });

  try {
    // 2) Create a chat completion using the special message array 
    // that includes { type: "image_url", image_url: { url } } 
    const completion = await openai.chat.completions.create({
      model: "gpt-4o", // or "gpt-4o", whichever doc you have access to
      messages: [
        {
          role: "user",
          content: [
            { type: "text", text: "Process this image and summarize it for me." },
            {
              type: "image_url",
              image_url: {
                url: imageUrl, // the S3 or any public link
              },
            },
          ],
        },
      ],
    });

    // 3) Return the text from the assistant
    return completion.choices[0].message;
  } catch (error) {
    console.error("Error calling OpenAI:", error);
    throw error;
  }
}


const [isDragging, setIsDragging] = useState(false);

function handleDragEnter(e) {
  e.preventDefault();
  e.stopPropagation();
  setIsDragging(true);
}

function handleDragOver(e) {
  e.preventDefault();
  e.stopPropagation();
  // Must prevent default to allow drop
}

function handleDragLeave(e) {
  e.preventDefault();
  e.stopPropagation();
  setIsDragging(false);
}

function handleDrop(e) {
  e.preventDefault();
  e.stopPropagation();
  setIsDragging(false);

  if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    // Suppose you want to handle single-file logic:
    handleFileChange({ target: { files: e.dataTransfer.files } });
  }
}


  

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      setCompleted(false);
      const file = e.target.files[0];

      try {
        let dataToUpload;
        let finalFileName;

        if (!isImage) {
          if (processVideoToggle) {
            // PROCESS the video
            dataToUpload = await processVideo(file);
            finalFileName = file.name.replace('.mp4', '_frames.zip');
          } else {
            // Upload RAW video
            dataToUpload = file;
            finalFileName = file.name;
          }
        } else {

          // ------------------ CODE THAT SUMMARIZES USING BASE64 URL LOCALLY USING CHATGPT o1, DIRECT FILES NOT SUPPORTED -----------------------

          // // Image logic: no processing, upload the image directly
          //   // Instead of calling summarizeImageViaOpenAI(s3Url),
          // // do the local approach:
          // try {
          // // Summarize *locally*, reading as base64
          // const summaryMsg = await summarizeLocalImage(file);
          // console.log("Got image summary:", summaryMsg.content);
        
          //   if (onSummaryReceived) {
          //     onSummaryReceived(summaryMsg.content || "No content");
          //   }
          // } catch (error) {
          //   console.error("Failed to summarize image:", error);
          // }
        
          // ------------------ CODE THAT SUMMARIZES USING BASE64 URL LOCALLY USING CHATGPT o1, DIRECT FILES NOT SUPPORTED END -----------------------
        

          dataToUpload = file;
          finalFileName = file.name;
        }

        setUploading(true);
        setUploadProgress(0);

        
        
        let accessLevelDecider;
        if (isImage) {
          accessLevelDecider = 'public';
        } else {
          accessLevelDecider = 'private';
        }

        const task = uploadData({
          path: ({ identityId }) =>
            // `private/${identityId}/${uploadPath}_${finalFileName}`,
          `${accessLevelDecider}/${identityId}/${projectName}/${uploadPath}_${finalFileName}`,
          data: dataToUpload,
          options: {
            contentType: isImage ? file.type : 'application/zip',
            // accessLevel: 'private',
            accessLevel: accessLevelDecider,
            onProgress: ({ transferredBytes, totalBytes }) => {
              if (totalBytes) {
                const percentCompleted = Math.round(
                  (transferredBytes * 100) / totalBytes
                );
                setUploadProgress(percentCompleted);
              }
            },
          },
        });

        const result = await task.result;

        // ------------------ CODE THAT SUMMARIZES USING FILE FROM S3 USING CHATGPT 4 -----------------------

        if (isImage) {
          try {
            const s3Url = 'https://evolverflowamplifystorageefdd9-dev.s3.us-east-2.amazonaws.com/' + result.path 
            const summaryMsg = await summarizeImageViaOpenAI(s3Url);
            console.log("Got image summary:", summaryMsg.content);

            if (onSummaryReceived) {
              onSummaryReceived(summaryMsg.content || "No content");
            }
          } catch (error) {
            console.error("Failed to summarize image:", error);
          }
        }

        // ------------------ CODE THAT SUMMARIZES USING FILE FROM S3 USING CHATGPT 4 END -----------------------


        if (onUploadComplete) {
          onUploadComplete(result);
          setFileName(finalFileName)
          console.log(result)
        }


        

        // alert(`${label} file uploaded successfully!`);
        setCompleted(true);
      } catch (err) {
        console.error(err);
        alert(`Error processing or uploading ${label} file.`);
      } finally {
        setProcessing(false);
        setUploading(false);
        setProcessingProgress(0);
        setUploadProgress(0);
        e.target.value = null; 
      }
    }
  };

  return (
    <div className={`w-full mb-4 p-4 border-solid rounded-lg 
      transition-colors duration-200 
      hover:border-blue-400 hover:bg-blue-50
      ${isDragging ? 'border-blue-400 bg-blue-50' : ''}`}
      style={{borderWidth: '1px'}}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}>
      <label className="block text-gray-700 font-bold mb-2">{label}</label>

      {completed && !uploading && !processing ? (
        <div className="mt-2 text-center text-green-600 font-bold">
          {fileName} Upload Complete!
        </div>
      ):(
        <>
        {/* If NOT an image, show a "Process Video?" checkbox */}
        {/*{!isImage && (
            <div className="mb-2 flex items-center space-x-2">
              <input
                type="checkbox"
                id={`${label}-processToggle`}
                checked={processVideoToggle}
                onChange={() => setProcessVideoToggle(!processVideoToggle)}
              />
              
              <label htmlFor={`${label}-processToggle`} className="text-sm text-gray-600">
                Extract Frames/Process Video? <span style={{fontWeight: '700'}}>*Select before choosing a file.</span>
              </label>
              
            </div>
          )}*/}

          {/* File Input */}
          <div 
              onClick={() => fileInputRef.current.click()}
              style={{ padding: '15px', borderRadius: '5px', borderStyle:'dashed', borderWidth: '1px', cursor: 'pointer'}}
              // plus the other events for drag & drop
            >
              <p className="text-center text-black-500">
                {completed
                  ? `${fileName} uploaded!`
                  : 'Drag & drop or click in this area to upload file.'}
              </p>

              <input
                type="file"
                accept={isImage ? 'image/*' : 'video/*'}
                onChange={handleFileChange}
                disabled={processing || uploading}
                ref={fileInputRef}
                style={{ display: 'none' }} // hide it
              />
            </div>

      {processing && (
        <div className="mt-2">
          <p className="text-center text-black">
            Processing frames... {processingProgress}%
          </p>
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className="h-4 rounded-full bg-yellow-500"
              style={{ width: `${processingProgress}%` }}
            ></div>
          </div>
        </div>
      )}

      {uploading && (
        <div className="mt-2">
        <div className="relative w-full bg-gray-200 rounded-full h-2 overflow-hidden">
          <div
            className="absolute left-0 top-0 h-2 transition-all duration-150"
            style={{ width: `${uploadProgress}%`, backgroundColor: '#00a2ff' }}
          />
        </div>
        <p className="text-sm text-center mt-1">{uploadProgress}%</p>
      </div>
      
      )}</>
      )}

      

      
    </div>
  );
};

export default FileUploader;
