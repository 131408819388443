// src/pages/LoginPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, signUp, confirmSignUp, resendSignUpCode, getCurrentUser, resetPassword, confirmResetPassword, signOut } from '@aws-amplify/auth';
import { useUser } from '../hooks/useUser';

export default function LoginPage() {
  const [step, setStep] = useState('signIn'); // "signIn" | "signUp" | "confirmSignUp" | "resetPassword" | "confirmReset"


  // Shared states for sign in & sign up
  const [username, setUsername] = useState('');       // Email
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // For Sign Up only
  const [name, setName] = useState('');               // For Sign Up only
  const [companyName, setCompanyName] = useState(''); // For Sign Up only

  // Confirmation code (for confirmSignUp)
  const [confirmationCode, setConfirmationCode] = useState('');

  // Password visibility toggles
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  // Loading spinner and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

 

  // Helpers to switch steps
  const switchToSignIn = () => {
    setStep('signIn');
    setError('');
  };
  const switchToSignUp = () => {
    setStep('signUp');
    setError('');
  };
  const switchToConfirmSignUp = () => {
    setStep('confirmSignUp');
    setError('');
  };


  const navigate = useNavigate();
  const { user, loading: userLoading, refreshUser, userAttrs } = useUser();

  useEffect (() => {

      if(user && !userLoading)
      {
        return
      } else if (!user && !userLoading && !userAttrs) {
        signOut();
      }
  }, [])
  
    // const [user, setUser] = useState(null);
  
    // // On mount, check if there's a currently logged-in user
    // useEffect(() => {
    //   let isMounted = true;
    //   async function loadUser() {
    //     try {
    //       const cUser = await getCurrentUser();
    //       if (isMounted) {
    //         setUser(cUser);
    //       }
    //     } catch (err) {
    //       // No user or error
    //       if (isMounted) setUser(null);
    //     }
    //   }
    //   loadUser();
    //   return () => {
    //     isMounted = false;
    //   };
    // }, []);
  
    // // If user is logged in, navigate to /portal
    // useEffect(() => {
    //   if (user) {
    //     navigate("/order-form");
    //   }
    // }, [user, navigate]);

    // useEffect(() => {
    //   if (!userLoading && user) {
    //     // For example, go straight to /order-form
    //     navigate('/order-form');
    //   }
    // }, [user, userLoading, navigate]);


  // -------------------------
  // 1) Sign In
  // -------------------------
  async function handleSignIn(e) {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const { isSignedIn, nextStep } = await signIn({ username, password });
      if (isSignedIn) {
        // alert('Signed in successfully!');
        refreshUser();
        navigate("/order-form");

        // You might redirect here, or show success
      } else if (nextStep?.signInStep === 'CONFIRM_SIGN_UP') {
        switchToConfirmSignUp();
      } else {
        console.log('Unknown nextStep:', nextStep);
      }
    } catch (err) {
      console.error('Error signing in:', err);
      setError(err.message || 'Error signing in');
    } finally {
      setLoading(false);
    }
  }

  // -------------------------
  // 2) Sign Up
  // -------------------------
  async function handleSignUp(e) {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }
    try {
      const { isSignUpComplete, nextStep } = await signUp({
        username, // Typically email
        password,
        options: {
          userAttributes: {
            name,
            'custom:companyName': companyName,
            'custom:hasDesignSnapshot': '',
            'custom:hasOnboarded': '',
          },
          autoSignIn: false,
        },
      });

      if (isSignUpComplete) {
        // Some user pools confirm immediately
        alert('Sign-up complete. You can now sign in.');
        switchToSignIn();
      } else if (nextStep?.signUpStep === 'CONFIRM_SIGN_UP') {
        switchToConfirmSignUp();
      }
    } catch (err) {
      console.error('Error signing up:', err);
      setError(err.message || 'Error signing up');
    } finally {
      setLoading(false);
    }
  }

  

  // -------------------------
  // 3) Confirm Sign Up
  // -------------------------
  async function handleConfirmSignUp(e) {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { isSignUpComplete } = await confirmSignUp({
        username,
        confirmationCode,
      });
      if (isSignUpComplete) {
        alert('Account confirmed! Please sign in.');
        switchToSignIn();
      } else {
        console.log('Sign up not fully complete (maybe more steps).');
      }
    } catch (err) {
      console.error('Error confirming sign up:', err);
      setError(err.message || 'Error confirming sign up');
    } finally {
      setLoading(false);
    }
  }

  async function handleResendCode() {
    setError('');
    try {
      await resendSignUpCode({ username });
      alert('Verification code resent!');
    } catch (err) {
      console.error('Error resending code:', err);
      setError(err.message || 'Error resending code');
    }
  }

  async function handleResetPassword(e) {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const output = await resetPassword({ username });
      const { nextStep } = output;
      console.log(nextStep)
      if (nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        // Switch to the confirm code step
        setStep('confirmReset');
        console.log(nextStep)

      } else if (nextStep.resetPasswordStep === 'DONE') {
        alert('Successfully reset your password. Please sign in.');
        setStep('signIn');
        console.log(nextStep)

      }
    } catch (err) {
      console.error('Error resetting password:', err);

      setError(err.message || 'Error resetting password');
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirmResetPassword(e) {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword: password,
      });
      alert('Password reset successful! Please sign in with your new password.');
      setStep('signIn');
      
    } catch (err) {
      console.error('Error confirming reset password:', err);
      setError(err.message || 'Error confirming reset password');
    } finally {
      setLoading(false);
    }
  }
  
  

  // -------------------------
  // RENDER
  // -------------------------
  // STEP A: Sign In
  if (step === 'signIn') {
    return (
      <div className="login-container">
        <div className="login-content">
          <div className='auth-modal-title '>
            <img
                src="logo192.png"
                alt="Logo"
                className="shake h-24 z-10 m-3"
              />
              <h2 className="text-2xl font-bold mb-4 text-center">Sign In</h2>
          </div>
          {error && <p className="text-red-500 mb-2">{error}</p>}

          <form onSubmit={handleSignIn}>
            <div className="mb-4">
              <label className="block font-bold mb-1">Email</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="your@email.com"
                required
              />
            </div>

            <div className="mb-4 relative">
              <label className="block font-bold mb-1">Password</label>
              <input
                type={showPassword1 ? 'text' : 'password'}
                className="w-full p-2 border rounded"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="••••••••"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-9 text-gray-600"
                onClick={() => setShowPassword1(!showPassword1)}
              >
                {showPassword1 ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
              </button>
            </div>
            <p className="mb-5 text-right" style={{marginTop: '-15px'}}>
              <button
                type="button"
                className="font-bold hover:underline"
                onClick={() => {
                  setError('');
                  setStep('resetPassword');
                }}
              >
                Forgot Password?
              </button>
            </p>


            {loading ? (
              <div className="text-center mt-4">
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                <p className="mt-2">Signing in...</p>
              </div>
            ) : (
              <div className='login-center-content'>
              <button
                type="submit"
                className="login-button"
              >
                Sign In
              </button>
              </div>
            )}
          </form>

          <p className="mt-4 text-center">
            Don’t have an account?{' '}
            <button className="font-bold text-blue-600 hover:underline" onClick={switchToSignUp}>
              Sign Up
            </button>
          </p>
        </div>
      </div>
    );
  }

  // STEP B: Sign Up
  if (step === 'signUp') {
    return (
      <div className="login-container" >
        <div className="login-content">
        <div className='auth-modal-title '>
            <img
                src="logo192.png"
                alt="Logo"
                className="shake h-24 z-10 m-3"
              />
          </div>
          <h2 className="text-2xl font-bold mb-4 text-center">Sign Up</h2>
          {error && <p className="text-red-500 mb-2">{error}</p>}

          <form onSubmit={handleSignUp}>
            <div className="mb-4">
              <label className="block font-bold mb-1">Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="John Doe"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Company Name</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Acme Inc."
                required
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Email</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="your@email.com"
                required
              />
            </div>

            <div className="mb-4 relative">
              <label className="block font-bold mb-1">Password</label>
              <input
                type={showPassword1 ? 'text' : 'password'}
                className="w-full p-2 border rounded"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="••••••••"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-9 text-gray-600"
                onClick={() => setShowPassword1(!showPassword1)}
              >
                {showPassword1 ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
              </button>
            </div>

            <div className="mb-4 relative">
              <label className="block font-bold mb-1">Confirm Password</label>
              <input
                type={showPassword2 ? 'text' : 'password'}
                className="w-full p-2 border rounded"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="••••••••"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-9 text-gray-600"
                onClick={() => setShowPassword2(!showPassword2)}
              >
                {showPassword2 ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
              </button>
            </div>

            {loading ? (
              <div className="text-center mt-4">
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                <p className="mt-2">Signing up...</p>
              </div>
            ) : (
              <div className='login-center-content'>
              <button
                type="submit"
                className="login-button w-full"
              >
                Sign Up
              </button>
              </div>
            )}
          </form>

          <p className="mt-4 text-center">
            Already have an account?{' '}
            <button className="font-bold text-blue-600 hover:underline" onClick={switchToSignIn}>
              Sign In
            </button>
          </p>
        </div>
      </div>
    );
  }

  // STEP C: Confirm Sign Up
  if (step === 'confirmSignUp') {
    return (
      <div className="login-container" >
        <div className="login-content">
        <div className='auth-modal-title '>
            <img
                src="logo192.png"
                alt="Logo"
                className="shake h-24 z-10 m-3"
              />
              <h2 className="text-2xl font-bold mb-4 text-center">Confirm Signup</h2>
          </div>
          {error && <p className="text-red-500 mb-2">{error}</p>}

          <form onSubmit={handleConfirmSignUp}>
            <p className="mb-4 text-gray-700">
              Enter the code sent to: <strong>{username}</strong>
            </p>

            <div className="mb-4">
              <label className="block font-bold mb-1">Confirmation Code</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                placeholder="123456"
                required
              />
            </div>

            <div className="flex items-center justify-between">
              {loading ? (
                <div className="text-center">
                  <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                  <p className="mt-2">Confirming...</p>
                </div>
              ) : (
                <div className='login-center-content'>
                <button
                  type="submit"
                  className="login-button w-full"
                >
                  Confirm
                </button>
                </div>
              )}
              <div className='login-center-content'>
              <button
                type="button"
                className="py-2 px-4 bg-gray-300 font-bold rounded hover:bg-gray-400"
                onClick={handleResendCode}
              >
                Resend Code
              </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  // STEP: Reset Password
  if (step === 'resetPassword') {
    return (
      <div className="login-container">
        <div className="login-content">
          <h2 className="text-2xl font-bold mb-4 text-center">Reset Password</h2>
          {error && <p className="text-red-500 mb-2">{error}</p>}

          <form onSubmit={handleResetPassword}>
            {/* Only need "username/email" here */}
            <div className="mb-4">
              <label className="block font-bold mb-1">Email</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="your@email.com"
                required
              />
            </div>

            {loading ? (
              <div className="text-center mt-4">
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                <p className="mt-2">Requesting reset...</p>
              </div>
            ) : (
              <button
                type="submit"
                className="w-full py-2 bg-blue-600 text-white font-bold mt-2 rounded hover:bg-blue-700"
              >
                Send Reset Code
              </button>
            )}
          </form>

          <p className="mt-4 text-center">
            <button className="font-bold text-blue-600 hover:underline" onClick={() => setStep('signIn')}>
              Back to Sign In
            </button>
          </p>
        </div>
      </div>
    );
  }

  // STEP: Confirm Reset
  if (step === 'confirmReset') {
    return (
      <div className="login-container">
        <div className="login-content">
          <h2 className="text-2xl font-bold mb-4 text-center">Confirm Reset</h2>
          {error && <p className="text-red-500 mb-2">{error}</p>}

          <form onSubmit={handleConfirmResetPassword}>
            <p className="mb-4 text-gray-700">We sent a code to your email: <strong>{username}</strong></p>

            {/* Code input */}
            <div className="mb-4">
              <label className="block font-bold mb-1">Reset Code</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                placeholder="123456"
                required
              />
            </div>

            {/* New password */}
            <div className="mb-4">
              <label className="block font-bold mb-1">New Password</label>
              <input
                type="password"
                className="w-full p-2 border rounded"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="••••••••"
                required
              />
            </div>

            {loading ? (
              <div className="text-center mt-4">
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
                <p className="mt-2">Confirming...</p>
              </div>
            ) : (
              <button
                type="submit"
                className="w-full py-2 bg-blue-600 text-white font-bold mt-2 rounded hover:bg-blue-700"
              >
                Reset Password
              </button>
            )}
          </form>
        </div>
      </div>
    );
  }



  // If we somehow land in a weird step, show nothing (or handle differently)
  return null;
}
