// src/components/Layout/Header.js

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import SignInModal from "../SignInModal";
import SignUpModal from "../SignUpModal";

// Import your context
import { UserContext } from "../../context/UserContext";

import "../../css/Modal.css";

const Header = () => {
  // --------------------------
  // 1) Grab user & signOut from context
  // --------------------------
  const { user, setUser, handleSignOut } = useContext(UserContext);

  // --------------------------
  // 2) Local UI states
  // --------------------------
  const [activeLink, setActiveLink] = useState(null);
  const [scrollActive, setScrollActive] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const navigate = useNavigate();

  // track scroll position for shadow effect
  useEffect(() => {
    const handleScroll = () => {
      setScrollActive(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // disable background scroll when hamburger menu is open
  useEffect(() => {
    if (showMobileMenu) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [showMobileMenu]);

  // --------------------------
  // 3) onSignInSuccess => close modal + setUser + navigate
  // --------------------------
  async function onSignInSuccess() {
    setShowSignIn(false);
    // The context’s effect should handle re-fetching user, 
    // or we can do:
    try {
      // If your context auto-refreshes the user, 
      // you might not even need to do setUser() here 
      // But if you want to do it immediately:
      // const cUser = await getCurrentUser();
      // setUser(cUser);

      navigate("/order-form");
    } catch (err) {
      console.log("Error after sign in success:", err);
    }
  }

  // 4) onSignUpSuccess => close sign-up modal + optionally sign them in
  async function onSignUpSuccess() {
    setShowSignUp(false);
    // e.g. navigate("/order-form") or let them sign in
  }

  // 5) Render
  return (
    <>
      <header
        className={
          "slide-in-down pb-1 fixed top-0 w-full z-30 bg-white-500 transition-all" +
          (scrollActive ? " shadow-custom pt-0" : " pt-2")
        }
        style={{ boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.5)" }}
      >
        <nav className="max-w-screen-xl mx-auto px-6 py-4 flex items-center justify-between">
          {/* Logo block */}
          <div className="flex items-center">
            <a href="/" className="relative flex items-center">
              <div className="relative">
                <img
                  src="assets/RF3D_web01.png"
                  alt="LogoA"
                  className={
                    "fade-in transition-all z-20 " +
                    (scrollActive ? "h-7 w-auto pt-1" : "h-10 w-auto p-1")
                  }
                />
                <img
                  src="assets/RF3D_web02.png"
                  alt="LogoB"
                  className={
                    "bounce-in-up transition-all z-20 " +
                    (scrollActive ? "h-7 w-auto pt-1 spinlogo" : "h-10 w-auto p-1")
                  }
                  style={
                    scrollActive
                      ? { marginTop: "-28px" }
                      : { marginTop: "-40px" }
                  }
                />
              </div>
            </a>
          </div>

          {/* Desktop Nav Links */}
          <ul className="hidden lg:flex items-center">
            {/* If no user, show some links */}
            {!user && (
              <>
                <LinkScroll
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  onSetActive={() => setActiveLink("about")}
                  className={
                    "px-4 py-2 mx-2 cursor-pointer font-semibold" +
                    (activeLink === "about"
                      ? " text-[#000006]"
                      : " text-black-500 hover:text-[#000006]")
                  }
                >
                  About
                </LinkScroll>
                <LinkScroll
                  to="feature"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  onSetActive={() => setActiveLink("feature")}
                  className={
                    "px-4 py-2 mx-2 cursor-pointer font-semibold" +
                    (activeLink === "feature"
                      ? " text-[#000006]"
                      : " text-black-500 hover:text-[#000006]")
                  }
                >
                  Feature
                </LinkScroll>
              </>
            )}

            {/* Auth actions */}
            <div className="header-auth-actions ml-12">
              {user ? (
                <>
                  {/* Hover-based Dropdown for Settings */}
                  <div className="relative inline-block text-left group">
                    <div className="header-button cursor-pointer">Settings</div>
                    <div className="hidden group-hover:block absolute right-0 w-48 border border-gray-200 rounded-md shadow-lg z-50" style={{background: 'white'}}>
                      <Link
                        to="/portal"
                        className="block px-4 py-2 text-sm text-black hover:bg-gray-100"
                      >
                        Project Dashboard
                      </Link>
                      <Link
                        to="/meeting"
                        className="block px-4 py-2 text-sm text-black hover:bg-gray-100"
                      >
                        Support
                      </Link>
                      <Link
                        to="/subscriptions"
                        className="block px-4 py-2 text-sm text-black hover:bg-gray-100"
                      >
                        Your Subscriptions
                      </Link>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      handleSignOut();
                      // navigate("/") if you want
                    }}
                    className="header-button ml-2"
                  >
                    Sign Out
                  </button>
                </>
              ) : (
                <button
                  className="header-button"
                  onClick={() => setShowSignIn(true)}
                >
                  Login
                </button>
              )}
            </div>
          </ul>

          {/* Hamburger for Mobile */}
          <button
            className="hamburger-button lg:hidden"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            ☰
          </button>
        </nav>
      </header>

      {/* Mobile Menu (Full Screen Overlay) */}
      {showMobileMenu && (
        <div className="mobile-menu-overlay">
          <div className="mobile-menu-content">
            <button
              className="mobile-menu-close"
              onClick={() => setShowMobileMenu(false)}
            >
              X
            </button>
            <div className="auth-modal-title">
              <img
                src="logo192.png"
                alt="Logo"
                className="shake h-24 z-10 mb-16"
              />
            </div>

            {!user && (
              <>
                <LinkScroll
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  onSetActive={() => setActiveLink("about")}
                  onClick={() => setShowMobileMenu(false)}
                  className="mobile-menu-link"
                >
                  About
                </LinkScroll>
                <LinkScroll
                  to="feature"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  onSetActive={() => setActiveLink("feature")}
                  onClick={() => setShowMobileMenu(false)}
                  className="mobile-menu-link"
                >
                  Feature
                </LinkScroll>
              </>
            )}

            {user ? (
              <>
                <Link
                  to="/portal"
                  className="mobile-menu-link"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Project Dashboard
                </Link>
                <Link
                  to="/meeting"
                  className="mobile-menu-link"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Support
                </Link>
                <Link
                  to="/subscriptions"
                  className="mobile-menu-link"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Your Subscriptions
                </Link>
                <button
                  className="mobile-menu-link"
                  style={{
                    borderRadius: "35px",
                    borderWidth: "3px",
                    padding: "10px 30px",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    handleSignOut();
                    setShowMobileMenu(false);
                  }}
                >
                  Sign Out
                </button>
              </>
            ) : (
              <button
                className="mobile-menu-link"
                style={{
                  borderRadius: "35px",
                  borderWidth: "3px",
                  padding: "10px 30px",
                  marginTop: "20px",
                }}
                onClick={() => {
                  setShowSignIn(true);
                  setShowMobileMenu(false);
                }}
              >
                Login
              </button>
            )}
          </div>
        </div>
      )}

      {/* Modal(s) for Sign In / Sign Up */}
      {showSignIn && (
        <SignInModal
          onClose={() => setShowSignIn(false)}
          onSignInSuccess={onSignInSuccess}
          onSwitchToSignUp={() => {
            setShowSignIn(false);
            setShowSignUp(true);
          }}
        />
      )}
      {showSignUp && (
        <SignUpModal
          onClose={() => setShowSignUp(false)}
          onSignUpSuccess={onSignUpSuccess}
          onSwitchToSignIn={() => {
            setShowSignUp(false);
            setShowSignIn(true);
          }}
        />
      )}
    </>
  );
};

export default Header;
