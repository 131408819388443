// src/pages/OrderForm.jsx

import React, { useState, useEffect } from 'react';
import { useUser } from '../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { updateUserAttributes } from '@aws-amplify/auth';
import { createOrder } from '../graphql/mutations';
import { client } from '../App';
import FileUploader from '../components/FileUploader';
import Joyride, {ACTIONS, EVENTS, STATUS, Step, CallBackProps, TooltipProps} from 'react-joyride'; // <-- import from react-joyride
import Confetti from '../components/Confetti';

function OrderForm() {
  // 1) Pull user info from context
  const { user, userAttrs, loading, refreshUser, refreshOrders } = useUser();
  const navigate = useNavigate();

  const [tutorialRun, setTutorialRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [customRequestDetail, setCustomRequestDetail] = useState('');
  const [submitting, setSubmitting] = useState(false);



  function handleTutorialNextStep(){
    if(tutorialRun) {
      setStepIndex(stepIndex + 1)

    }
  }

  function handleTutorialPrevStep(){
    setStepIndex(stepIndex - 1)
  }

  function handleTutorialSkip(){
    setStepIndex(0)
    setTutorialRun(false)
  }


  function MyCustomTooltip(props: TooltipProps) {
    const {
      // Built-in props from Joyride
      step,
      isLastStep,
      primaryProps, // onClick & text for the "Next" or "Finish" button
      backProps,    // onClick & text for the "Back" button
      closeProps,   // onClick & text for the "Close" / "Skip" button
  
      // You can also read `content`, `title`, etc. from `step`
      // if you want to customize them further.
    } = props;

    return (
      <div style={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: 10,
        padding: '1rem',
        maxWidth: 300,
        position: 'relative'
      }}>
        {/* If the step has a title, show it */}
        {step.title && <h3 style={{ marginTop: 0, textAlign: 'center', fontWeight:'500', fontSize:'1.2rem', marginBottom: '0.3rem' }}>{step.title}</h3>}
  
        {/* The main step content */}
        <p>{step.content}</p>
  
        {/* Custom "Back" and "Next/Finish" buttons */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem'}}>
          {/* The "Back" button (only if not the first step, etc.) */}
          {stepIndex !== 0 && (
            <button
              // {...backProps} // merges the built-in logic from Joyride
              onClick={handleTutorialPrevStep}
              style={{ padding: '0.5rem 1rem', backgroundColor: '#000006', color: 'white', borderRadius: '25px', textAlign: 'center'  }}
            >
              Back
            </button>
          )}

          {/* "Skip" or "Close" button, which uses Joyride's built-in skip/close logic */}
        {!isLastStep && (
          <button
          {...closeProps} // merges Joyride's built-in skip/close logic
          onClick={handleTutorialSkip}
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#ccc',
            color: '#333',
            borderRadius: '25px'
          }}
        >
          Skip
        </button>
        )}
        
  
          {/* "Next" or "Finish" button if it's the last step */}
          <button
            // {...(isLastStep ? closeProps : primaryProps)}
            onClick={handleTutorialNextStep}
            style={{ padding: '0.5rem 1rem', backgroundColor: '#000006', color: 'white', borderRadius: '25px', textAlign: 'center'  }}
          >
            {isLastStep ? 'Skip Submit & Finish' : 'Next'}
          </button>
        </div>
      </div>
    );
  }

  // Joyride steps array
  const tutorialSteps = [
    {
      target: '#tutorial-angle-help',
      title: "Angles",
      content: 'Here is where you upload your videos. If you need a tutorial on how to film one, click the "?" button to get tutorials on that and examples.',
      disableBeacon: true,
    },
    {
      target: '#tutorial-add-angle',
      content: 'If you need additional angles, click here to add up to 4 angles total.',
      showSkipButton: false,
      continuous: false
    },
    {
      target: '#tutorial-remove-angle',
      content: 'If you added an angle by mistake or want to remove the last angle, click here. You must keep at least one angle.'
    },
    {
      target: '#tutorial-design-photo',
      content: 'Here is where you upload your design photo. If you need a tutorial, click the "?" button.',
    },
    {
      target: '#tutorial-company-name',
      content: 'Your company name is auto-filled, but you can click the pencil icon and change it if you are ordering on behalf of another company.',
      placement: 'top'
    },
    {
      target: '#tutorial-project-name',
      content: 'Enter your project name here and click Next',
    },
    {
      target: '#tutorial-payment-section',
      content: 'Choose a service level. Currently, Design Snapshot is $0 as a limited-time offer!',
    },
    {
      target: '#tutorial-submit',
      content: "Finally, click here to submit and you're all set!",
      placement: 'top'
    }
  ];

  const HEADER_OFFSET = 80;

  useEffect(() => {
    // setTutorialRun(true);  // If you want it to start automatically
  }, []);



  // 2) Local states for form logic
  const [hasDesignSnapshotPermanent, setHasDesignSnapshotPermanent] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [designSummary, setDesignSummary] = useState('');
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [paypalLoaded, setPaypalLoaded] = useState(false);

  // For disabling radio spamming:
  const [serviceCooldown, setServiceCooldown] = useState(false);

  const [isCompanyNameEditable, setIsCompanyNameEditable] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [designPath, setDesignPath] = useState(null);

  const [helpVisible, setHelpVisible] = useState(false);
  const [helpAngleId, setHelpAngleId] = useState(null);

  function openAngleHelp(angleId) {
    setHelpAngleId(angleId);
    setHelpVisible(true);
    // setStepIndex(stepIndex + 1)
    handleTutorialNextStep();
  }

  function closeAngleHelp() {
    setHelpVisible(false);
    setHelpAngleId(null);
  }

  async function markTutorialComplete() {
    try {
      const existingAttr = userAttrs['custom:hasOnboarded'] || "";

      const newValue = existingAttr.includes("orderformtutorialcompleted")
        ? existingAttr
        : existingAttr + ",orderformtutorialcompleted";
        
      if (!existingAttr.includes("orderformtutorialcompleted")) {
        await updateUserAttributes({
          userAttributes: {
            'custom:hasOnboarded': newValue,
          },
        });
        console.log("Updated user attribute to indicate tutorial is complete");
        refreshUser();
      }
      
      
    } catch (err) {
      console.error("Failed to update user attribute for tutorial completion:", err);
    }
  }

  function handleJoyrideCallback(data) {
    const { type, action, index, status, step } = data;
  
    // If Joyride can't find the target element => skip that step
    if (type === EVENTS.TARGET_NOT_FOUND) {
      console.log(type, action, index, status, step)
      // By default, Joyride tries to do "next" automatically. 
      // We'll explicitly skip to the next step, or end if we’re at the last step.
      if (index + 1 < tutorialSteps.length) {
        if (action === ACTIONS.NEXT) {
          setStepIndex(index + 1);
        } else if (action === ACTIONS.PREV) {
          setStepIndex(index - 1);
        }
        
      } else {
        setTutorialRun(false);
        setStepIndex(0);
      }
      return; // exit early
    }
  
    // Smooth-scroll to center of element before showing step
    if (type === EVENTS.STEP_BEFORE && step?.target) {
      const el = document.querySelector(step.target);
      if (el) {
        const rect = el.getBoundingClientRect();
        const elementCenter =
          rect.top + window.scrollY - window.innerHeight / 2 + rect.height / 2;
        const scrollPosition = elementCenter - HEADER_OFFSET;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    }
  
    // // If user hits Next or Prev in the tooltip
    // if (type === EVENTS.STEP_AFTER) {
    //   if (action === ACTIONS.NEXT) {
    //     console.log("NEXT", type, action, index, status, step)

    //     // setStepIndex(prev => prev + 1);
    //   } else if (action === ACTIONS.PREV) {
    //     console.log("PREV", type, action, index, status, step)

    //     // setStepIndex(prev => (prev > 0 ? prev - 1 : 0));
    //   }
    // }
  
    // If tutorial finishes or user skips
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setTutorialRun(false);
      markTutorialComplete();
      setStepIndex(0);
    }
  }
  


  function sanitizeProjectName(name) {
    return name
      .trim()
      .replace(/\s+/g, '_')           // Convert spaces to underscores
      .replace(/[^A-Za-z0-9_\-]/g, '') // Remove invalid chars except underscore, dash
      .toLowerCase();                  // optional, if you want all-lower
  }

  // ---- (A) We store the angle uploads in an array of objects
  // E.g. [ { id: 1, label: "Angle 1", path: null }, ... ]
  const [angles, setAngles] = useState([
    { id: 1, label: 'Angle 1', path: null },
  ]);

  // If userAttrs is loaded, set local state for "hasDesignSnapshotPermanent" & "companyName"
  useEffect(() => {
    if (userAttrs) {
      setCompanyName(userAttrs['custom:companyName'] || '');
      const purchased = userAttrs['custom:hasDesignSnapshot'] === 'true';
      setHasDesignSnapshotPermanent(purchased);

      // If user hasn't onboarded => redirect
      // if (userAttrs['custom:hasOnboarded'] !== 'true') {
      //   navigate('/onboarding');
      // }

        // ----- AUTOMATICALLY START TUTORIAL IF NOT COMPLETED YET -----
      // Suppose the attribute is `custom:completedTutorial`.
      // We'll store a comma-separated list of completed tutorials.
      const completedOnboarding = userAttrs['custom:hasOnboarded'] || "";

      if (!completedOnboarding.includes('true')) {
          navigate('/onboarding');
        }

      else if (!completedOnboarding.includes("orderformtutorialcompleted")) {
        setTutorialRun(true); // auto-run the tutorial
      }
    }
  }, [userAttrs, navigate]);

  useEffect(() => {
    // Check PayPal availability
    const checkPayPalLoaded = () => {
      if (window.paypal) {
        setPaypalLoaded(true);
      } else {
        setTimeout(checkPayPalLoaded, 100);
      }
    };
    checkPayPalLoaded();
  }, []);

  useEffect(() => {
    // Re-render PayPal if needed
    if (!paypalLoaded || !selectedPayment) return;
    const cost = getServicePrice(selectedPayment);
    if (cost === 0) return;

    const container = document.getElementById('paypal-button-container');
    if (container) {
      container.innerHTML = '';
      window.paypal
        .Buttons({
          style: { layout: 'horizontal' },
          funding: { allowed: [window.paypal.FUNDING.PAYPAL, window.paypal.FUNDING.CARD] },
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: { value: cost.toString() },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            await actions.order.capture();
            setPaymentComplete(true);
            // auto submit if everything is ready
            const allUploaded = angles.every((a) => a.path !== null);
            if (allUploaded && projectName.trim()) {
              handleSubmit();
            } else {
              alert('Please fill out the rest of the form first.');
            }
          },
        })
        .render('#paypal-button-container');
    }
  }, [paypalLoaded, selectedPayment, angles, projectName]);

  // If context is still loading the user, show spinner
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-blue-500 border-t-transparent" />
          <p className="mt-4 text-lg text-gray-600">Loading user...</p>
        </div>
      </div>
    );
  }

  // If user is null => not logged in => show "Access Denied"
  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold">Access Denied</h2>
          <p>You must be logged in to access this Order Form.</p>
          <a href="/" className="text-blue-500 underline">Return Home</a>
        </div>
      </div>
    );
  }

  // Payment logic
  function getServicePrice(service) {
    switch (service) {
      case 'design_snapshot':
        return 0;
      case '2d_composition':
        return 100;
      case '3d_composition':
        return 250;
      case 'custom_request':
      // might be 0 or set by an admin. For now, return 0
        return 0;
      default:
        return 0;
    }
  }

  // plus sign to add new angles, up to 4 total
  function handleAddAngle() {
    if (angles.length < 4) {
      console.log("step set to 2")
      
      const newId = angles.length + 1;
      setAngles((prev) => [
        ...prev,
        { id: newId, label: `Angle ${newId}`, path: null },
      ]);
      
        handleTutorialNextStep();

    } else {
      alert('You can only upload up to 4 angles.');
    }
  }

  function handleRemoveAngle(angleId) {
    if (angles.length === 1) {
      alert("You must keep at least one angle!");
      return;
    }
    handleTutorialNextStep();
    setAngles(prev => prev.filter(angle => angle.id !== angleId));
  }
  

  // handle changes from each FileUploader
  const handleAngleUpload = (angleId) => (result) => {
    const newPath = result.path;
    handleTutorialNextStep();

    setAngles((prev) =>
      prev.map((angle) =>
        angle.id === angleId ? { ...angle, path: newPath } : angle
      )
    );
  };

  function handleDesignSummary(summary) {
    setDesignSummary(summary);
  }

  function handleServiceChange(e) {
    setSelectedPayment(e.target.value);
    setServiceCooldown(true);
    setTimeout(() => {
      setServiceCooldown(false);
      setStepIndex(s => s + 1)
    }, 1000);
  }

  

  // Submission
  async function handleSubmit(e) {
    if (e) e.preventDefault();


    if (!designPath) {
      alert('Please upload the Design File first.');
      return;
    }
  

    // check angles
    const allUploaded = angles.every((a) => a.path !== null);
    if (!allUploaded) {
      alert('All angle fields are required. Please let them finish uploading.');
      return;
    }
    
    

    if (!selectedPayment) {
      alert('Please select a Service Level first');
      return;
    }
    
    if (selectedPayment === 'custom_request' && customRequestDetail.trim() === '') {
      alert('Please fill out your custom request details before submitting');
      return;
    }

    let finalPaymentVal = selectedPayment; 
    if (selectedPayment === 'custom_request') {
      finalPaymentVal = `Custom: ${customRequestDetail.trim() || 'No details'}`;
    }
    
    // If cost > 0, require paymentComplete
    const cost = getServicePrice(selectedPayment);
    if (cost > 0 && !paymentComplete) {
      alert('Please pay before submitting.');
      return;
    }

    setSubmitting(true);

    try {
      // Prepare the data you want to store
      const newOrder = {
        projectName,
        companyName,
        angles: angles.map(a => a.path), // array of S3 paths
        designPath,
        designSummary,
        // selectedPayment,
        selectedPayment: finalPaymentVal,
        cost,          // 0, 100, or 250 from getServicePrice
        status: 'queued',  // or any default status you prefer
      };

      // Actually create the order in DynamoDB
      const result = await client.graphql({
        query: createOrder,
        variables: { input: newOrder }
      });
      console.log('Order created:', result?.data?.createOrder);
      // Optionally do something:
      // navigate('/my-projects');
    } catch (error) {
      console.error('Error creating order in AWS:', error);
      alert('Submission failed. Please try again.');
      setSubmitting(false);
    }
    // GForm submission
    const formId = '1FAIpQLScU5UQVhYcxIv9kVmDY8YiOxR4-itzbnX5tQWJJeD9jANh2uQ';
    const formAction = `https://docs.google.com/forms/d/e/${formId}/formResponse`;
    const formData = new FormData();

    // append angles
    // Let's say we map each angle i => a different entry
    // e.g. angle1 => entry.1802208956, angle2 => entry.1096840975, etc.
    // Adjust for however your GForm is set up. We'll do something like:
    const angleEntries = [
      'entry.1802208956', // angle1
      'entry.1096840975', // angle2
      'entry.1221502708', // angle3
      'entry.1493995173', // angle4
    ];
    

    angles.forEach((angle, i) => {
      // i => index 0..3
      // angle.path => S3 path
      formData.append(angleEntries[i], angle.path || '');
    });

    formData.append('entry.1529791536', designSummary || '');
    formData.append('entry.712719117', designPath || '');


    // also store your companyName & projectName
    formData.append('entry.1626505983', companyName);
    formData.append('entry.268040468', projectName);
    // formData.append('entry.379860776', selectedPayment);
    formData.append('entry.379860776', finalPaymentVal);

    

    try {
      await fetch(formAction, { method: 'POST', body: formData, mode: 'no-cors' });
      // clear states
      setCompanyName('');
      setProjectName('');
      setAngles([{ id: 1, label: 'Angle 1', path: null }]);
      setDesignSummary('');
      setSubmitted(true);
    } catch (err) {
      alert('Error submitting form');
      setSubmitting(false);
    }
    refreshOrders();
  }

  function handleCreateAnotherOrder() {
    setCompanyName('');
    setProjectName('');
    setAngles([{ id: 1, label: 'Angle 1', path: null }]);
    setDesignSummary('');
    setSelectedPayment(null);
    setPaymentComplete(false);
    setSubmitted(false);
  }

  // If submitted => show success
 // If submitted => show success
if (submitted) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <Confetti />
      <div className="text-center p-16 bg-white rounded shadow-lg" style={{ maxWidth: '800px' }}>
        {/* Large check icon from Font Awesome */}
        <i className="fas fa-check-circle text-green-500 text-7xl mb-4"></i>

        {/* "Congratulations" heading */}
        <h2 className="text-3xl font-extrabold text-green-600 mb-2">
          Order Submitted!
        </h2>

        {/* Subheading / confirmation message */}
        <p className="text-gray-700 mb-6">
          Your order has been successfully submitted. We’ll keep you posted with updates!
        </p>

        {/* "Create Another" button */}
        <button
          onClick={handleCreateAnotherOrder}
          className="px-6 py-3 font-semibold text-white bg-green-500 hover:bg-green-600 rounded-full transition-colors"
        >
          Create Another Order
        </button>
      </div>
    </div>
  );
}


  const canSubmit = getServicePrice(selectedPayment) === 0 || paymentComplete;

  return (
    <div className="min-h-screen flex items-center justify-center mt-0 mb-0">
      {/* JOYRIDE: place near top-level so it can highlight everything */}
      <Joyride
        steps={tutorialSteps}
        run={tutorialRun}
        stepIndex={stepIndex}         // we control which step is active
        tooltipComponent={MyCustomTooltip}
        continuous
        // continuous={false}            // let *our* click increment, not the built-in Next button
        // scrollToFirstStep
        // scrollToAlignment="center"
        disableOverlayClose={true}    // disallow user to close by clicking outside
        disableScrolling
        showSkipButton
        showProgress
        spotlightClicks={true}
        
        callback={handleJoyrideCallback}

        styles={{
          
          buttonBack: { display: 'none' },
          buttonNext: { display: 'none' },
          // The main "options" object customizes the default Joyride overlay color & zIndex
          options: {
            primaryColor: '#000006',
            zIndex: 998,                  // ensure it's on top of everything
            overlayColor: 'rgba(0,0,0,0.0)' // your 50% black overlay
          },
          // "spotlight" is the actual highlight around the target:
          spotlight: {
            // If you want a soft rectangle with slightly rounded corners:
            borderRadius: 6,
            // or if you want a circle cutout, do a large borderRadius like 9999
            // borderRadius: 9999,

            // The key to the "cutout" effect is forcing a big shadow behind it:
            boxShadow: '0 0 0 9999px rgba(0,0,0,0.6)',

            // Make sure the spotlight itself is transparent:
            backgroundColor: 'transparent',
          },
          // If you want to style the overlay or tooltip further, you can do:
          // overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          // tooltip: { ... },
          // etc.
        }}
        // optional callback if you want events: 
        // callback={(data) => console.log(data)}
      />
      <div className="container mx-auto p-2 max-w-lg bg-white ">
        <h1 className="text-3xl font-bold text-center mb-2">
          Order Form
          {/* Welcome back, {userAttrs?.name || 'User'}! */}
          <br />
          <span style={{ color: '#555', fontWeight: '500', fontSize: '1.5rem' }}>
          {userAttrs?.name || 'User'}
            <br/>
            ({userAttrs?.['custom:companyName'] || 'Unknown Company'} Workspace)
          </span>
        </h1>

        {/* A button to start tutorial on demand */}
        <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
        Dont know what to do?&nbsp; 
          <button
            type="button"
            onClick={() => setTutorialRun(true)}
            style={{
              color: 'black',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Click here
          </button>
        </div>

        

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* dynamic angle uploads */}
          {angles.map((angle, idx) => {
  const isLast = angle.id === angles[angles.length - 1].id;
  return (
    <div 
    id="tutorial-angle-help" // ID for Joyride
    key={angle.id} style={{ position: 'relative' }}>
      {/* 
        (A) The row with the angle label + "?" 
      */}
      <div 
       style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
        <span style={{ fontWeight: 'bold' }}>{angle.label}</span>
        <button
          
          type="button"
          style={{
            marginLeft: '6px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            color: '#555',
            fontSize: '1rem',
          }}
          onClick={() => openAngleHelp(angle.id)} // see step below
        >
          <i 
          className="fas fa-question-circle" />
        </button>
      </div>

      {/* (B) The FileUploader itself */}
      <FileUploader
        key={angle.id}
        label=""  // We no longer pass the label here since we put it above
        projectName={sanitizeProjectName(projectName)}
        uploadPath={`angle${angle.id}`}
        isImage={false}
        onSummaryReceived={handleDesignSummary}
        onUploadComplete={handleAngleUpload(angle.id)}
      />

      {angles.length > 1 && isLast && angle.id !== "1" && (
        <button
          id="tutorial-remove-angle" // ID for Joyride
          className="px-2 py-1 font-semibold text-white rounded-md bg-red-300 hover:bg-gray-600"
          style={{ color: 'white', marginTop: '8px' }}
          type="button"
          onClick={() => handleRemoveAngle(angle.id)}
        >
          <i className="fas fa-trash"></i>
        </button>
      )}
    </div>
  );
})}


          {/* plus button if less than 4 angles */}
          {angles.length < 4 && (
            <div style={{textAlign: 'center'}}>
              <button
                id="tutorial-add-angle"  // ID for Joyride
                type="button"
                onClick={handleAddAngle}
                className="px-4 py-2 font-semibold text-white rounded-md bg-gray-500 hover:bg-gray-600"
              >
                + Add Angle
              </button>
            </div>
          )}

        <div id="tutorial-design-photo">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
        <span style={{fontWeight: 'bold'}}>Design Photo</span>
        <button
          id="tutorial-design-help" // ID for Joyride
          type="button"
          style={{
            marginLeft: '6px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            color: '#555',
            fontSize: '1rem',
          }}
          onClick={() => openAngleHelp()} // see step below
        >
          <i className="fas fa-question-circle" />
        </button>
        </div>
        <FileUploader
          label=""
          projectName={sanitizeProjectName(projectName)}
          uploadPath="design"
          isImage={true}
          onSummaryReceived={handleDesignSummary} // This triggers your AI summary
          onUploadComplete={(result) => setDesignPath(result.path)}
        />
        
        </div>


          {/* optional design summary display */}
          {designSummary && (
            <div className="mt-4 p-2 border rounded">
              <h3 className="font-bold">Design Summary:</h3>
              <p>{designSummary}</p>
            </div>
          )}

          {/* Company name */}
          <div id="tutorial-company-name" className="mb-4 relative">
            <label className="block text-gray-700 font-bold mb-2">Company Name</label>
            <div className="relative">
              <input
                type="text"
                value={companyName}
                disabled={!isCompanyNameEditable}
                onChange={(e) => setCompanyName(e.target.value)}
                className="w-full px-3 py-2 border rounded-md"
                required
              />
              <button
                type="button"
                onClick={() => setIsCompanyNameEditable(!isCompanyNameEditable)}
                className="absolute right-2 top-1/2 -translate-y-1/2 p-1 hover:bg-gray-100 rounded"
              >
                <i className={`fas ${isCompanyNameEditable ? 'fa-lock' : 'fa-pencil'}`} />
              </button>
            </div>
          </div>

          {/* Project name */}
          <div id="tutorial-project-name" className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Project Name</label>
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
          </div>

          {/* Payment radio group */}
          <div id="tutorial-payment-section" className="mb-6">
            <h3 className="text-lg font-bold mb-4">Select Service Level</h3>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="design_snapshot"
                  name="payment_option"
                  value="design_snapshot"
                  checked={selectedPayment === 'design_snapshot'}
                  disabled={serviceCooldown}
                  onChange={handleServiceChange}
                />
                <label htmlFor="design_snapshot">Design Snapshot ($0)</label>
              </div>

              {/* <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="2d_composition"
                  name="payment_option"
                  value="2d_composition"
                  checked={selectedPayment === '2d_composition'}
                  disabled={serviceCooldown}
                  onChange={handleServiceChange}
                />
                <label htmlFor="2d_composition">2D Photo Composition ($100)</label>
              </div> */}

              {/* <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="3d_composition"
                  name="payment_option"
                  value="3d_composition"
                  checked={selectedPayment === '3d_composition'}
                  disabled={serviceCooldown}
                  onChange={handleServiceChange}
                />
                <label htmlFor="3d_composition">3D Composition ($250)</label>
              </div> */}

              {/* Custom Request */}
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="custom_request"
                  name="payment_option"
                  value="custom_request"
                  checked={selectedPayment === 'custom_request'}
                  disabled={serviceCooldown}
                  onChange={handleServiceChange}
                />
                <label htmlFor="custom_request">Custom Request (Price Quote Request)</label>
              </div>

              {/*
                If user chooses custom_request, show a text field
                for additional details
              */}
              {selectedPayment === 'custom_request' && (
                <div className="ml-6 mt-2">
                  <label className="block text-gray-700 font-bold mb-1">
                    Describe your custom request
                  </label>
                  <input
                    type="text"
                    value={customRequestDetail}
                    onChange={(e) => setCustomRequestDetail(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md"
                    placeholder="e.g. advanced 3D design, specialized edits..."
                  />
                </div>
              )}

              {serviceCooldown && (
                <div className="flex items-center mb-2">
                  <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-blue-500 border-t-transparent mr-2" />
                  <span className="text-sm text-gray-600">Updating Payment…</span>
                </div>
              )}
            </div>

            {/* If cost>0 => show PayPal button */}
            {selectedPayment && getServicePrice(selectedPayment) > 0 && (
              <div className="mt-4">
                <div id="paypal-button-container" />
              </div>
            )}
          </div>

          {/* Submit button */}
          <button
            id="tutorial-submit"
            type="submit"
            disabled={!canSubmit || submitting}
            className={`w-full px-4 py-2 font-semibold ${
              (!canSubmit || submitting) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            style={{
              borderWidth: '5px',
              border: 'solid',
              borderRadius: '25px',
              color: 'white',
              backgroundColor: 'black'
            }}
          >
            {submitting ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-2 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
                Submitting...
              </span>
            ) : (
              'Submit'
            )}
          </button>
          {/* <button
            id="tutorial-submit"
            type="submit"
            disabled={!canSubmit}
            className={`w-full px-4 py-2 font-semibold ${
              !canSubmit ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            style={{ borderWidth: '5px', border: 'solid', borderRadius: '25px', color: 'white', backgroundColor: 'black' }}
          >
            Submit
          </button> */}
        </form>

      </div>
      {helpVisible && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
    }}
    onClick={closeAngleHelp} // close on outside click
  >
    {/* The video container */}
    <div
      style={{
        position: 'relative',
        width: '80%',
        maxWidth: '1200px',
        height: '80%',
        backgroundColor: '#fff',
        borderRadius: '6px',
        overflow: 'hidden',
      }}
      onClick={(e) => e.stopPropagation()} // prevent closing when clicking inside
    >
      {/* Close button in the top-right corner */}
      <button
        onClick={closeAngleHelp}
        style={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          background: 'rgba(0,0,0,0.5)',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '1.2rem',
          padding: '0.25rem 0.5rem',
          zIndex: 10,
        }}
      >
        <i className="fas fa-times" />
      </button>

      {/* Title or instructions if you want */}
      <h3 style={{ margin: '0.8rem 1rem', fontSize: '1.2rem', color: '#333' }}>
        How to Capture Angle {helpAngleId}
      </h3>

      {/* Video wrapper at full container size */}
      <div style={{ position: 'relative', width: '100%', height: '85%' }}>
        <iframe
          src="https://www.youtube.com/embed/Yt3-GNuFBNw?si=SU1JmJiS9rkRXdX8?autoplay=1"
          title="How to Shoot Angle"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      </div>
    </div>
  </div>
)}



    </div>
  );
}

export default OrderForm;
